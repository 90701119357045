import { useState } from "react"
import PropTypes from "prop-types"

// ** MUI
import { Box, Divider, Grid, IconButton, Modal, Tab, Tabs } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Styles
import "../components/styles/AdditionalColumnStyles.css"

// ** Custom
import Header from "../layout/Header"
import WarehouseRequestTabView from "../components/WarehouseRequestTabView"
import TransferRequestTabView from "../components/TransferRequestTabView"
import { style } from "../utils"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

const DashboardView = ({
  selectedGlobalProgramId,
  allWRTData,
  handleEditFormSubmit,
  handleSkipHazmat,
  handleReopenOrCancelWRT,
  leftMenuDrawerOpen,
  isAlertModalOpen,
  handleAlertModal,
}) => {
  const title = "Pending Forms"
  const [tabValue, setTabValue] = useState(JSON.parse(localStorage.getItem("dashboardTabView")) || 0)

  const handleChange = (event, newValue) => {
    localStorage.setItem("dashboardTabView", JSON.stringify(newValue))
    setTabValue(newValue)
  }

  const CustomTabPanel = (props) => {
    const { children, value, index, ...other } = props

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    )
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  }

  const allyProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    }
  }

  return (
    <>
      {isAlertModalOpen && (
        <AlertModal
          shouldOpen={true}
          handleAlertModal={handleAlertModal}
        />
      )}
      <Header title={title} />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleChange}
            centered
          >
            <Tab
              label="Warehouse Requests"
              {...allyProps(0)}
            />
            <Tab
              label="Transfer Requests"
              {...allyProps(1)}
            />
          </Tabs>
        </Box>
        <CustomTabPanel
          value={tabValue}
          index={0}
        >
          <WarehouseRequestTabView
            selectedGlobalProgramId={selectedGlobalProgramId}
            allWRTData={allWRTData}
            handleEditFormSubmit={handleEditFormSubmit}
            handleSkipHazmat={handleSkipHazmat}
            handleReopenOrCancelWRT={handleReopenOrCancelWRT}
            leftMenuDrawerOpen={leftMenuDrawerOpen}
            title={title}
          />
        </CustomTabPanel>
        <CustomTabPanel
          value={tabValue}
          index={1}
        >
          <TransferRequestTabView selectedGlobalProgramId={selectedGlobalProgramId} />
        </CustomTabPanel>
      </Box>
    </>
  )
}

export default DashboardView

export const AlertModal = ({ shouldOpen, handleAlertModal }) => {
  const [open, setOpen] = useState(shouldOpen)

  return (
    <Modal
      open={open}
      onClose={() => handleAlertModal()}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid
          container
          spacing={2}
          columns={11}
        >
          <IconButton onClick={() => handleAlertModal()}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid
          container
          spacing={2}
          columns={11}
        >
          <Grid
            item
            xs={11}
            sx={{ paddingTop: "15px !important" }}
          >
            <Divider
              sx={{ fontFamily: "Merriweather" }}
              textAlign="left"
            >
              Attention!
            </Divider>
          </Grid>
          <Grid
            item
            xs={11}
            sx={{ textAlign: "left" }}
          >
            Before you start using AWIMS, notify an administrator so they may assign you to your roles and programs. Once assigned, please log out and
            log back in for assignments to take effect.
          </Grid>
          <Grid
            item
            xs={11}
          >
            <Divider />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
