import { Typography } from "@mui/material"

const Header = ({ title }) => {
  return (
    <>
      <div>
        <Typography
          data-testid="page-title"
          variant="h1"
          color="inherit"
          noWrap
          sx={{ flexGrow: 1, textAlign: "left", marginBottom: 1, paddingBottom: 1 }}
        >
          {title}
        </Typography>
      </div>
    </>
  )
}

export default Header
