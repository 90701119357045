import { useContext, useEffect, useState, useMemo } from "react"
import { Link, useLocation } from "react-router-dom"

// ** MUI
import AddIcon from "@mui/icons-material/Add"
import ArchiveIcon from "@mui/icons-material/Archive"
import EditIcon from "@mui/icons-material/Edit"
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart"
import TrackChangesIcon from "@mui/icons-material/TrackChanges"
import PrintIcon from "@mui/icons-material/Print"
import { Button, Stack } from "@mui/material"
import { GridActionsCellItem } from "@mui/x-data-grid"

// ** Toastify
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"
import { useAuth } from "react-oidc-context"

// ** Custom
import { CustomStripedGrid } from "../components/CustomStripedGrid"
import { CustomModal } from "../components/CustomModal"
import { FormDialog } from "../components/FormDialog"
import InventoryRecordsButtonGroup from "../components/InventoryRecordsButtonGroup"
import Header from "../layout/Header"
import "../components/styles/AdditionalColumnStyles.css"
import { PercentageSelectionModal } from "../components/PercentageSelectionModal"
import LoadingBackdrop, {
  applyCaps,
  createQuery,
  filterByIdActiveStateNotIssued,
  getFormattedDate,
  filterActiveNotIssuedData,
  notify,
  permissionCheck,
} from "../utils"

const InventoryRecordsTableView = ({
  selectedGlobalProgramId,
  handleAddItemsToCart,
  handlePendingChangesCount,
  pendingChangesCount,
  leftMenuDrawerOpen,
  handleTransferItems,
  pendingChangesUpdate,
  handlePendingChangesUpdate,
  inventoryUpdate,
  handleInventoryUpdate,
  handleUpdateIsInvLoading,
  isInvLoading,
}) => {
  let context = useContext(AppContext)
  const notPermitted = permissionCheck(["Government Customer", "Program Customer", "Inventory Manager"], context.usersRoles)
  const [loaded, setLoaded] = useState(false)
  const [filteredData, setFilteredData] = useState("")
  const [warehouseData, setWarehouseData] = useState("")
  const [selectedProgramId, setSelectedProgramId] = useState(JSON.parse(localStorage.getItem("selectedProgramId")))
  const [selectedWarehouseId, setSelectedWarehouseId] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isPercentageSelectionModalOpen, setIsPercentageSelectionModalOpen] = useState(false)
  const [selectedDeleteId, setSelectedDeleteId] = useState("")
  // const [error, setError] = useState("")
  const auth = useAuth()
  const location = useLocation()
  const title = "Inventory"
  const [selectedReport, setSelectedReport] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false)
  let pathname = location.state?.path || window.location.pathname

  useEffect(() => {
    if (selectedProgramId !== selectedGlobalProgramId) setSelectedProgramId(selectedGlobalProgramId)
  }, [selectedProgramId, selectedGlobalProgramId])

  useEffect(() => {
    if (
      (selectedProgramId && !selectedReport) ||
      (selectedProgramId && selectedReport === "inventory_completion_report") ||
      (selectedProgramId && selectedReport === "TIS_format")
    ) {
      fetchFilteredByAP(selectedProgramId)
    } else if (selectedReport?.includes("percentage")) {
      // Clear data grid before grabbing percentage of inventory.
      setFilteredData([])
      togglePercentageSelectionModal()
    } else if ((!selectedProgramId && !selectedReport) || (!selectedProgramId && selectedReport)) {
      setFilteredData("")
    }
  }, [selectedProgramId, selectedReport, inventoryUpdate])

  useEffect(() => {
    if (filteredData) {
      setLoaded(true)
    }
  }, [filteredData])

  const generateBlankInventoryRecordsTemplate = () => {
    const blankTemplate = context.inventory_records.formFields.reduce((acc, currVal) => {
      let isProgram = currVal.field.includes("ArmyProgram")
      if (!isProgram && !acc[currVal.field]) {
        acc[currVal.field] = ""
      } else if (isProgram && !acc[currVal.field]) {
        let checkForProgram = localStorage.getItem("selectedProgramId")
        acc[currVal.field] = checkForProgram ? JSON.parse(checkForProgram) : ""
      }
      return acc
    }, {})
    context.blankInventoryRecordsTemplate = blankTemplate
  }

  /// ignore for now V
  const handleWarehouseSelect = (selection) => {
    setSelectedWarehouseId(selection)
  }
  // ignore for now ^

  const handleFilterReportsSelect = (selection) => {
    setSelectedReport(selection)
  }

  const handleMonthlyPercentageReport = async (percent) => {
    setIsLoading(true)
    handleUpdateIsInvLoading(true, "inventory")
    try {
      await filterMonthlyPercentage(selectedProgramId, percent)
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      handleUpdateIsInvLoading(false, "inventory")
      notify("error", `There was a problem generating your ${applyCaps(selectedReport)}. Please try again.`)
    }
  }

  // Creates a query to grab all inventory for either a sub-program or a parent program's sub-programs' inventory.
  const filterForInventory = async (apId) => {
    let program = context["finalProgramsList"]?.find((program) => program.id === apId)
    if (program.subPrograms) {
      // Add parent program to list in case it has inventory.
      let subIds = program.subPrograms.map((sub) => sub.id)
      let parentAndSubIds = [`${program.id}`, ...subIds]
      let query = createQuery("InventoryRecords", parentAndSubIds)
      return await apiCalls.getRecords(`InventoryRecords?%24filter=${query}`)
    } else return await apiCalls.getRecords(`InventoryRecords?%24filter=ManagementRecordArmyProgramId%20eq%20${apId}`)
  }

  const fetchFilteredByAP = async (apId) => {
    setIsLoading(true)
    handleUpdateIsInvLoading(true, "inventory")
    try {
      let filteredByAP = await filterForInventory(apId)
      let activeFilteredByAp = await filterActiveNotIssuedData(filteredByAP.data.value)
      setFilteredData(activeFilteredByAp)
      setIsLoading(false)
      handleUpdateIsInvLoading(false, "inventory")
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      handleUpdateIsInvLoading(false, "inventory")
      notify("error", `There was a problem filtering your inventory. Please try again.`)
    }
  }

  const filterMonthlyPercentage = async (apId, percent) => {
    let monthlyPercentage = await apiCalls.getMonthlyPercentage(apId, percent)
    let activeFilteredByMP = await filterActiveNotIssuedData(monthlyPercentage.data.recordsToBeInventoried)
    setFilteredData(activeFilteredByMP)
    setIsLoading(false)
    handleUpdateIsInvLoading(false, "inventory")
  }

  // Not sure if still need - depends on whether filtering by warehouses should happen for records or reports:
  const fetchFilteredByWh = async (whId) => {
    let filteredByWh = await apiCalls.filterInventoryRecordsByWarehouseId(whId)
    let activeFilteredByWh = await filterActiveNotIssuedData(filteredByWh.data.value)
    setFilteredData(activeFilteredByWh)
  }
  const fetchFilteredByAPandWh = async (apId, whId) => {
    // NOTE: Will need to consider parent/sub-programs if this ends up getting used.
    let filteredByAPandWh = await apiCalls.filterInventoryRecordsByAPandWhIds(apId, whId)
    let activeFilteredByAPandWh = await filterActiveNotIssuedData(filteredByAPandWh.data.value)
    setFilteredData(activeFilteredByAPandWh)
  }
  const handleInvDelete = async (id, path) => {
    try {
      await apiCalls.patchRecord(id, path, "ActiveState", "replace", false)
      if (filteredData) {
        const revisedFilteredData = await filterByIdActiveStateNotIssued(filteredData, id)
        setFilteredData(revisedFilteredData)
      }
      setSelectedDeleteId("")
      notify("success", `${title} item archived successfully.`)
    } catch (error) {
      console.error("error1", error)
      notify("error", "There was a problem archiving your item. Please try again.")
    }
  }

  const toggleFormDialog = () => {
    setIsFormDialogOpen(true)
  }

  const handleFormDialogClose = () => {
    setIsFormDialogOpen(false)
  }

  const toggleModal = () => {
    setIsModalOpen(true)
  }

  const togglePercentageSelectionModal = () => {
    setIsPercentageSelectionModalOpen(true)
  }

  const handleConfirmDelete = (response) => {
    if (response) {
      handleInvDelete(selectedDeleteId.id, "InventoryRecords")
    }
    setIsModalOpen(false)
  }

  const handleFetchMonthlyPercentageOfRecords = (response) => {
    if (response == "10") {
      handleMonthlyPercentageReport(10)
    } else if (response == "100") {
      handleMonthlyPercentageReport(100)
    }
    setIsPercentageSelectionModalOpen(false)
  }

  const getProgramName = (apId) => {
    if (apId) {
      let foundAP = context.allProgramsData?.find((el) => el.id === apId)
      return foundAP?.name
    } else {
      return ""
    }
  }

  // Not sure if below is needed for COSIS, maybe when get to issue 438

  const formatRowSelectionModel = (ids) => {
    let dataToMap = filteredData
    const selectedIds = new Set(ids)
    const selectedRowData = dataToMap?.filter((el) => selectedIds.has(el.id.toString()))
    return selectedRowData
  }

  const onRowSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel)
  }

  const clearSelected = () => {
    setTimeout(() => {
      setRowSelectionModel([])
    }, 2000)
  }

  const { initialState, reportDefs } = context.inventory_records

  const invFormFields = context.inventory_records.formFields

  // NOTE: temp permissions for dev
  // CHECK this needs to be ironed out in APP
  let canAddNewRecord = context.isAdmin || context.isReceiving || context.isICP || context.isTL
  let canEditRecord = context.isAdmin || !context.isCustomer
  let canSoftDeleteRecord = context.isAdmin || context.isICP || context.isTL

  const reportColumnsToShow = context.inventory_records.reportDefs[selectedReport]
  let columnsToUpdate = selectedReport ? reportColumnsToShow : context.inventory_records.columns
  const updateColumns =
    columnsToUpdate &&
    columnsToUpdate.map((el) => {
      if (el.field === "actions") {
        el.getActions = (params) => {
          return [
            <Stack showInMenu={true}>
              {canEditRecord && (
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  component={Link}
                  to={`${pathname}/edit/${params.id}`}
                  state={{ title: title }}
                  showInMenu={true}
                />
              )}
              {canSoftDeleteRecord && (
                <GridActionsCellItem
                  icon={<ArchiveIcon />}
                  label="Archive"
                  onClick={() => {
                    toggleModal()
                    setSelectedDeleteId(params.row)
                  }}
                  showInMenu={true}
                />
              )}
              <GridActionsCellItem
                icon={<TrackChangesIcon />}
                label="Edit History"
                component={Link}
                to={`${pathname}/historic_changes/${params.id}`}
                state={{ pathname: pathname }}
                showInMenu={true}
              />
              {!notPermitted && (
                <GridActionsCellItem
                  icon={<PrintIcon />}
                  label="Print RFID Label"
                  to={`${pathname}/print/${params.id}`}
                  component={Link}
                  state={{ pathname: pathname, params: params.row }}
                  showInMenu={true}
                />
              )}
            </Stack>,
          ]
        }
      }
      return el
    })

  const memoizedColumns = useMemo(() => updateColumns, [])

  const listOfRecordsToMassUpdate = async (field, value) => {
    let fullList = rowSelectionModel.map((id) => {
      let foundRec = filteredData.find((row) => row.id === id)
      let updatedRec = { ...foundRec, [field]: value }
      return updatedRec
    })
    return fullList
  }

  const handleMassInventoryUpdate = async (field, value) => {
    let listOfRecords = await listOfRecordsToMassUpdate(field, value)
    try {
      // Make changes automatically to COSIS Dates and CC; these shouldn't be marked as pending changes.
      const pattern = new RegExp(/^(?!.*(cosisDueDate|cosisCompleteDate|conditionCode|packagingDeficiency)).*$/)

      // If Inventory Managers make changes to AESIP or Asset Ids fields, it does not have to go through the pending approval process.
      const inventoryManagerChanges =
        context.isInventoryManager && (field === "armyEnterpriseSystemIntegrationProgramSerializationIndicator" || field === "assetIdentification")

      let resp = !pattern.test(field)
        ? await Promise.all(listOfRecords.map(async (rec) => await apiCalls.patchRecord(rec.id, "InventoryRecords", field, "replace", value)))
        : inventoryManagerChanges
        ? await Promise.all(listOfRecords.map(async (rec) => await apiCalls.patchRecord(rec.id, "InventoryRecords", field, "replace", value)))
        : await Promise.all(listOfRecords.map(async (rec) => await apiCalls.putPendingRecordChanges(rec, "InventoryRecords", rec.id)))

      notify(
        "success",
        `Your edits to ${rowSelectionModel.length} inventory records have been ${
          !pattern.test(field) || inventoryManagerChanges ? "successfully changed" : "submitted for pending approval"
        }.`
      )

      setIsFormDialogOpen(false)
      handleFormDialogClose()
      clearSelected()

      // If a non-pending change field, update the main inventory table after submission.
      if (!pattern.test(field) || inventoryManagerChanges) handleInventoryUpdate()
      else {
        handlePendingChangesUpdate()
        handlePendingChangesCount(resp?.length, title)
      }
    } catch (error) {
      console.log("error", error)
      notify("error", "There was a problem submitting your edits. Please see below.")
      return error
    }
  }

  if (loaded) {
    return (
      <>
        <Header title={title} />
        <div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
        {isModalOpen && (
          <CustomModal
            shouldOpen={true}
            handleConfirmDelete={handleConfirmDelete}
            pathname={pathname}
            title="Confirm Record Archive"
            reference={selectedDeleteId.propertyNumber}
          />
        )}
        {isFormDialogOpen && (
          <FormDialog
            invFormFields={invFormFields}
            shouldOpen={true}
            onClose={handleFormDialogClose}
            handleMassInventoryUpdate={handleMassInventoryUpdate}
          />
        )}
        {isPercentageSelectionModalOpen && (
          <PercentageSelectionModal
            shouldOpen={true}
            handleFetchMonthlyPercentageOfRecords={handleFetchMonthlyPercentageOfRecords}
          />
        )}
        <section className="inventory-btns-container">
          <div className="add-import-wrapper">
            {canAddNewRecord && (
              <Link
                to={`${pathname}/new`}
                state={{ pathname: pathname, title: title }}
              >
                <Button
                  sx={{ marginRight: "10px", color: "black", fontSize: ".87rem", fontWeight: "400" }}
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => {
                    generateBlankInventoryRecordsTemplate()
                  }}
                >
                  Add Item
                </Button>
              </Link>
            )}
            {!selectedReport && !context.isInventoryManager && (
              <Button
                sx={{ fontSize: ".87rem", fontWeight: "400", color: "black" }}
                variant="contained"
                startIcon={<ShoppingCartIcon />}
                disabled={!rowSelectionModel.length}
                onClick={() => {
                  handleAddItemsToCart(formatRowSelectionModel(rowSelectionModel))
                  clearSelected()
                }}
              >
                Add to Cart
              </Button>
            )}
          </div>
          <InventoryRecordsButtonGroup
            warehouseData={warehouseData}
            handleWarehouseSelect={handleWarehouseSelect}
            reportDefs={reportDefs}
            selectedProgramId={selectedProgramId}
            selectedReport={selectedReport}
            isInvLoading={isInvLoading}
            handleFilterReportsSelect={handleFilterReportsSelect}
            pathname={pathname}
          />
        </section>
        <div style={{ height: "75vh", width: "100%" }}>
          <CustomStripedGrid
            handleInventoryUpdate={handleInventoryUpdate}
            handlePendingChangesUpdate={handlePendingChangesUpdate}
            handlePendingChangesCount={handlePendingChangesCount}
            checkboxSelection={true}
            pathname={pathname}
            data={filteredData}
            columns={selectedReport ? reportColumnsToShow : memoizedColumns}
            initialState={initialState}
            // loading={isInvLoading}
            loading={isLoading}
            title={
              selectedReport
                ? `AWIMS ${getProgramName(selectedProgramId)} ${applyCaps(selectedReport)} -`
                : `${getProgramName(selectedProgramId)} ${title}`
            }
            rowSelectionModel={rowSelectionModel}
            toggleFormDialog={toggleFormDialog}
            // setRowSelectionModel={setRowSelectionModel}
            onRowSelectionModelChange={onRowSelectionModelChange}
            selectedGlobalProgramId={selectedGlobalProgramId}
            handleTransferItems={handleTransferItems}
            formattedTransferItems={formatRowSelectionModel(rowSelectionModel)}
            clearSelectedRows={clearSelected}
          />
        </div>
      </>
    )
  } else {
    return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
  }
}

export default InventoryRecordsTableView
