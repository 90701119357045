import { Dialog, DialogTitle, DialogContent } from "@mui/material"
import LinearProgress from "@mui/material/LinearProgress"

const CustomProgressDialog = ({ open, progress, title }) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {progress ? (
          <LinearProgress
            variant="determinate"
            value={progress}
          />
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CustomProgressDialog
