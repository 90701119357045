import * as React from "react"

// ** MUI
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material"

// ** Custom
import { applyCaps } from "../utils"

export default function FilterReportsSelect({ selectedArmyProgramId, reportDefs, handleFilterReportsSelect, isInvLoading, pathname }) {
  const [report, setReport] = React.useState("")
  const isCOSIS = pathname.includes("COSIS")

  let titleToDisplay = isCOSIS ? "Select COSIS Report" : "Select Report"
  let bgColor = isCOSIS ? "white" : null
  const handleReportChange = (event) => {
    setReport(event.target.value)
    handleFilterReportsSelect(event.target.value)
  }

  const filteredReports = Object.keys(reportDefs).filter((report) =>
    pathname.includes("COSIS") ? report.includes("COSIS") : !report.includes("COSIS")
  )

  const finalReportOptions = filteredReports.map((report) => {
    return (
      <MenuItem
        key={report}
        value={report}
        disabled={isInvLoading}
      >
        {applyCaps(report)}
      </MenuItem>
    )
  })

  return (
    <Box sx={{ minWidth: 250, marginRight: "10px", backgroundColor: bgColor }}>
      <FormControl fullWidth>
        <InputLabel
          shrink
          id="report-select-label"
        >
          Generate
        </InputLabel>
        <Select
          sx={{ height: "50px" }}
          displayEmpty
          labelId="report-select-label"
          id="report-select"
          value={report}
          label="Generate"
          disabled={isInvLoading}
          onChange={handleReportChange}
          input={
            <OutlinedInput
              notched
              label="Generate"
            />
          }
        >
          <MenuItem
            key="select-report"
            value=""
            disabled={isCOSIS}
          >
            {titleToDisplay}
          </MenuItem>
          {finalReportOptions}
        </Select>
      </FormControl>
    </Box>
  )
}
