import { useEffect, useState } from "react"

// ** MUI
import { Button, FormControl, FormControlLabel, Grid, IconButton, Popover, Radio, RadioGroup, TextField, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import { DataGridPremium, GridActionsCellItem, GridToolbarContainer, GridRowModes } from "@mui/x-data-grid-premium"
import AddIcon from "@mui/icons-material/Add"
import CancelIcon from "@mui/icons-material/Cancel"
import DeleteIcon from "@mui/icons-material/Delete"
import EditIcon from "@mui/icons-material/Edit"
import InfoIcon from "@mui/icons-material/Info"
import SaveIcon from "@mui/icons-material/Save"

const StyledDataGrid = styled("div")(() => ({
  height: 400,
  width: "100%",
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    color: "#1a3e72",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
}))

const SerialNumbersInputForm = ({ radioValue, handleRadioChange, form, handleChange, finalSerialNumberRows, handleFinalSerialNumberRows }) => {
  const [rows, setRows] = useState(finalSerialNumberRows)
  const [rowModesModel, setRowModesModel] = useState({})
  const [pasteSerialNumbers, setPasteSerialNumbers] = useState("")
  const [anchorPopover, setAnchorPopover] = useState()
  const openPopover = Boolean(anchorPopover)

  useEffect(() => {
    handleFinalSerialNumberRows(rows)
  }, [rows])

  const SerialNumberToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          startIcon={<AddIcon />}
          onClick={handleAddClick}
        >
          Add
        </Button>
      </GridToolbarContainer>
    )
  }

  const handlePastedSerialNumbers = (e) => {
    setPasteSerialNumbers(e.target.value)
  }

  const handlePopoverOpen = (e) => {
    setAnchorPopover(e.currentTarget)
  }

  const handlePopoverClose = (e) => {
    setAnchorPopover(null)
  }

  // Handles adding in a list of serial numbers without having to enter each one.
  const handleBlurSerialNumbers = () => {
    let splitString = pasteSerialNumbers.split(/[\n;,\s]/) // Delimeters to split by (may need to add more).

    // Remove any empty strings that result from the split.
    splitString = splitString.filter((serialNumber) => serialNumber)

    let newRows = splitString.map((serialNumber) => {
      return {
        id: Math.floor(Math.random() * 100000),
        serialNumber: serialNumber,
        isNew: false,
      }
    })

    let copyOfRows = [...rows]
    newRows.forEach((s) => {
      copyOfRows.push(s)
    })
    setRows(copyOfRows)
    setPasteSerialNumbers("")
  }

  const handleAddClick = () => {
    const id = Math.floor(Math.random() * 100000)
    setRows((oldRows) => [...oldRows, { id, serialNumber: "", isNew: true }])
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "serialNumber" },
    }))
  }

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } })
  }

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } })
  }

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id))
  }

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    })

    const editedRow = rows.find((row) => row.id === id)
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id))
    }
  }

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel)
  }

  const columns = [
    { field: "serialNumber", headerName: "Serial Number", mindWidth: 900, flex: 1, editable: true },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="error"
            />,
          ]
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="default"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="error"
          />,
        ]
      },
    },
  ]

  return (
    <div>
      <Grid
        item
        sx={{ minWidth: "100%", marginBottom: "15px" }}
      >
        <FormControl sx={{ minWidth: "100%" }}>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={radioValue}
          >
            <FormControlLabel
              value="single"
              control={<Radio onClick={handleRadioChange} />}
              label="Single Entry"
              color="default"
            />
            <FormControlLabel
              value="multiple"
              control={<Radio onClick={handleRadioChange} />}
              label="Multiple Entries"
              color="default"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item>
        {radioValue === "single" ? (
          <Grid
            item
            sx={{ minWidth: "100%" }}
          >
            <FormControl sx={{ minWidth: "100%" }}>
              <TextField
                id="serialNumber"
                label="Serial #"
                type="text"
                value={form["serialNumber"]}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                size="small"
              />
            </FormControl>
          </Grid>
        ) : radioValue === "multiple" ? (
          <>
            <Grid
              container
              columns={12}
            >
              <Grid
                item
                xs={2.5}
                sx={{ minWidth: "100px", marginBottom: "30px" }}
              >
                <TextField
                  id="pasteSerialNumbers"
                  label="Serial Number(s)"
                  type="text"
                  value={pasteSerialNumbers}
                  onChange={handlePastedSerialNumbers}
                  onBlur={handleBlurSerialNumbers}
                  InputLabelProps={{ shrink: true }}
                  size="small"
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{ marginLeft: "-20px", marginBottom: "30px" }}
              >
                <IconButton
                  color="info"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                >
                  <InfoIcon />
                </IconButton>
                <Popover
                  id="mouse-over-popover"
                  sx={{ pointerEvents: "none" }}
                  open={openPopover}
                  anchorEl={anchorPopover}
                  anchorOrigin={{
                    vertical: "center",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  slotProps={{
                    paper: {
                      sx: {
                        width: "300px",
                        height: "140px",
                      },
                    },
                  }}
                  onClose={handlePopoverClose}
                  disableRestoreFocus
                >
                  <Typography sx={{ p: 1, textAlign: "left" }}>
                    A list of serial numbers can be pasted into this field (values can be separated by a comma, semi-colon, tab, or space) to populate
                    the table below. Click out of the field when finished pasting.
                  </Typography>
                </Popover>
              </Grid>
            </Grid>
            <Grid>
              <StyledDataGrid>
                <DataGridPremium
                  columns={columns}
                  rows={rows}
                  editMode="row"
                  disableRowSelectionOnClick
                  getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row")}
                  rowModesModel={rowModesModel}
                  onCellKeyDown={(params, event) => {
                    if (event.key === "Enter") handleAddClick()
                  }}
                  onRowModesModelChange={handleRowModesModelChange}
                  processRowUpdate={processRowUpdate}
                  onProcessRowUpdateError={(e) => console.error("Process error:", e)}
                  slots={{ toolbar: SerialNumberToolbar }}
                  sx={{
                    boxShadow: 3,
                    border: "1px solid",
                    borderColor: "rgba(16, 80, 117, 0.5)",
                    "& .even-row.MuiDataGrid-row": {
                      backgroundColor: "rgba(219, 231, 229, 0.35)",
                      "&:hover, &.Mui-hovered": {
                        backgroundColor: "rgba(84, 187, 253, 0.1)",
                        "@media (hover: none)": {
                          backgroundColor: "transparent",
                        },
                      },
                    },
                    "& .MuiDataGrid-toolbarContainer": {
                      background: "rgba(219, 231, 229, 0.35)",
                      border: "1px solid",
                      borderColor: "rgba(16, 80, 117, 0.5)",
                    },
                  }}
                />
              </StyledDataGrid>
            </Grid>
          </>
        ) : (
          // Show empty div if neither radio buttons are selected.
          <div />
        )}
      </Grid>
    </div>
  )
}

export default SerialNumbersInputForm
