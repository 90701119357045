import * as React from "react"

// ** MUI
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material"

export default function FilterIssuedYearSelect({ handleIssuedYearChange }) {
  const [year, setYear] = React.useState("")

  const handleYearChange = (event) => {
    setYear(event.target.value)
    handleIssuedYearChange(event.target.value)
  }

  const issuedYears = ["2023", "2024", "2025"]

  const yearOptions = issuedYears.map((year) => {
    return (
      <MenuItem
        key={year}
        value={year}
      >
        {year}
      </MenuItem>
    )
  })

  return (
    <Box sx={{ minWidth: 180, backgroundColor: "white" }}>
      <FormControl fullWidth>
        <InputLabel
          shrink
          id="year-select-label"
        >
          Issued Year
        </InputLabel>
        <Select
          sx={{ height: "50px" }}
          displayEmpty
          labelId="year-select-label"
          id="year-select"
          value={year}
          label="Issued Year"
          onChange={handleYearChange}
          input={
            <OutlinedInput
              notched
              label="Issued Year"
            />
          }
        >
          <MenuItem
            key="select-year"
            value=""
            disabled
          >
            Select Year
          </MenuItem>
          {yearOptions}
        </Select>
      </FormControl>
    </Box>
  )
}
