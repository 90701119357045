export const themeVariables = {
  app: {
    palette: {
      primary: "#BBC893",
      text: "#1e3141",
      border: "rgb(224, 224, 224)",
      bg: "#f2f2f9",
      green: "#018356",
      lightGreen: "#dbe7e5",
      yellow: "#f8b04f"
    }
  },
  menu: {
    palette: {
      selected: "#c8c8c8"
    }
  }
}