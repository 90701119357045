import { useEffect, useState } from "react"

// ** Toastify
import "react-toastify/dist/ReactToastify.css"

// ** MUI
import { DataGridPremium } from "@mui/x-data-grid-premium"

// ** Styles
import "../../components/styles/AdditionalColumnStyles.css"

const IssuedItemsGrid = ({ issuedData, issuedYear }) => {
  const [rows, setRows] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setRows(issuedData)
  }, [issuedData, issuedYear])

  const issuedItemColumns = [
    {
      field: "issuedDate",
      headerName: "Issued Date",
      minWidth: 20,
      align: "left",
      headerAlign: "left",
    },
    { field: "documentNumber", headerName: "Document Number", minWidth: 150, align: "left", headerAlign: "left" },
    { field: "nationalStockNumber", headerName: "NSN", minWidth: 130, align: "left", headerAlign: "left" },
    { field: "partNumber", headerName: "Part #", minWidth: 120, align: "left", headerAlign: "left" },
    { field: "serialNumber", headerName: "Serial #", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "nomenclature", headerName: "Nomenclature", minWidth: 250, align: "left", headerAlign: "left" },
    { field: "nationalItemIdentificationNumber", headerName: "NIIN", minWidth: 100, align: "left", headerAlign: "left" },
    { field: "quantity", headerName: "Qty", type: "number", minWidth: 20, align: "left", headerAlign: "left" },
    { field: "unitOfIssue", headerName: "UI", minWidth: 20, align: "left", headerAlign: "left" },
    { field: "propertyNumber", headerName: "Property #", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "radioFrequencyIdentificationNumber", headerName: "RFID", minWidth: 230, align: "left", headerAlign: "left" },
    {
      field: "conditionCode",
      headerName: "CC",
      minWidth: 50,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "hazardousMaterielIndicatorCode",
      headerName: "HMIC",
      minWidth: 50,
      align: "left",
      headerAlign: "left",
    },
    {
      field: "unitPrice",
      headerName: "Unit Price",
      type: "number",
      minWidth: 80,
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
      valueFormatter: ({ value }) =>
        value != null
          ? "$ " +
            Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 2,
              minimumFractionDigits: 2,
            }).format(value)
          : "",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      type: "number",
      minWidth: 120,
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
      valueFormatter: ({ value }) =>
        value != null
          ? "$ " +
            Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 2,
              minimumFractionDigits: 2,
            }).format(value)
          : "",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "endBalance",
      headerName: "End Balance",
      minWidth: 80,
      align: "left",
      headerAlign: "left",
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(3),
      valueFormatter: ({ value }) =>
        value != null
          ? Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 3,
              minimumFractionDigits: 0,
            }).format(value)
          : "",
    },
    { field: "location", headerName: "Location", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "site", headerName: "Site", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "unitOfMeasurement", headerName: "UOM", minWidth: 20, align: "left", headerAlign: "left" },
    { field: "federalSupplyClassification", headerName: "FSC", minWidth: 25, align: "left", headerAlign: "left" },
    { field: "uniqueItemIdentifier", headerName: "UII", minWidth: 30, align: "left", headerAlign: "left" },
    { field: "commercialAndGovernmentEntityCode", headerName: "CAGE Code", minWidth: 80, align: "left", headerAlign: "left" },
    {
      field: "armyEnterpriseSystemIntegrationProgramSerializationIndicator",
      headerName: "AESIP Serialization Indicator",
      minWidth: 100,
      align: "left",
      headerAlign: "left",
    },
    { field: "assetIdentification", headerName: "Asset ID", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "sourceOfSupply", headerName: "SOS", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "automaticReturnItemList", headerName: "ARIL", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "accountingRequirementsCode", headerName: "ARC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "controlledInventoryItemCode", headerName: "CIIC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "criticalityCode", headerName: "CRITL Code", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "demilitarizationCode", headerName: "DEMIL Code", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "specialControlItemCode", headerName: "SCIC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "supplyCategoriesOfMaterielCode", headerName: "SCMC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "shelfLifeCode", headerName: "SLC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "specialRequirementsCode", headerName: "SRC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "materielCategoryStructureFourAndFive", headerName: "MATCAT 4 & 5", minWidth: 140, align: "left", headerAlign: "left" },
    {
      field: "exchangePrice",
      headerName: "Exchange Price",
      minWidth: 140,
      align: "left",
      headerAlign: "left",
      valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
      valueFormatter: ({ value }) =>
        value != null
          ? "$ " +
            Intl.NumberFormat("en-US", {
              maximumFractionsDigits: 2,
              minimumFractionDigits: 2,
            }).format(value)
          : "",
    },
    { field: "typeOfStorageArmy", headerName: "TOS ARMY", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "hazardousCharacteristicsCode", headerName: "HCC", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "controlledInventoryItemCodeAmdf", headerName: "CIIC AMDF", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "methodOfPreservationArmy", headerName: "ARMY MOP", minWidth: 80, align: "left", headerAlign: "left" },
    { field: "electrostaticDischargeCode", headerName: "ESD", minWidth: 80, align: "left", headerAlign: "left" },
  ]

  return (
    <DataGridPremium
      rows={rows}
      columns={issuedItemColumns}
      disableRowSelectionOnClick
      getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row")}
      loading={isLoading}
      density="compact"
      sx={{
        boxShadow: 3,
        border: "1px solid",
        borderColor: "rgba(16, 80, 117, 0.5)",
        "& .even-row.MuiDataGrid-row": {
          backgroundColor: "rgba(219, 231, 229, 0.35)",
          "&:hover, &.Mui-hovered": {
            backgroundColor: "rgba(84, 187, 253, 0.1)",
            "@media (hover: none)": {
              backgroundColor: "transparent",
            },
          },
        },
        "& .MuiDataGrid-columnHeader": {
          fontWeight: "700",
          fontSize: ".92rem",
        },
      }}
    />
  )
}

export default IssuedItemsGrid
