import { useNavigate } from "react-router-dom"

// ** Toastify
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// ** MUI
import { Container, IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

// ** Styles
import "../components/styles/CustomFormStyles.css"

// ** Custom
import Header from "../layout/Header"
import BrowserPrintComponent from "../components/BrowserPrinter/printerComponent"

const BrowserPrinterView = ({ leftMenuDrawerOpen }) => {
  const navigate = useNavigate()
  const title = "Print RFID Label"

  // THOUGHT
  // would it be worth it to have some of hte inventory record info displaying
  // before the label ish just in case wht if someone thinks they clickedon the wrong record?
  // or some sort of instructions for connecting to a printer

  return (
    <Container className="form-bg">
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
      <div className="back-btn-wrapper">
        <IconButton
          onClick={() => navigate(-1)}
          size="large"
        >
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
      </div>
      <div className="header-padding">
        <Header title={title} />
      </div>
      <BrowserPrintComponent leftMenuDrawerOpen={leftMenuDrawerOpen} />
    </Container>
  )
}

export default BrowserPrinterView
