import { useEffect, useState } from "react"
import React from "react"

// ** MUI
import { 
    Divider,
    Grid,
    Typography,
 } from "@mui/material"
import { DataGridPremium } from "@mui/x-data-grid-premium"
import { styled } from '@mui/material/styles';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'

// ** Toastify
import { ToastContainer } from "react-toastify";

const TransferItemsTable = ({ 
    tableItems, 
    handleTableItems, 
    rowSelectionModel, 
    onRowSelectionModelChange,
    handleTransferItems 
}) => {
    const [rows, setRows] = useState(tableItems)

    const StyledDataGrid = styled('div')(({ theme }) => ({
        height: 300,
        width: '100%',
        '& .MuiDataGrid-cell--editing': {
            backgroundColor: 'rgb(255,215,115, 0.19)',
            color: '#1a3e72',
            '& .MuiInputBase-root': {
            height: '100%',
            },
        },
        '& .Mui-error': {
            backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.1})`,
            color: theme.palette.error.main,
        },
    }))

    useEffect(() => {
        setRows(tableItems)
    }, [tableItems])

    const handleRowUpdate = (updatedRow, originalRow) => {
        updatedRow.totalCost = updatedRow.quantity * updatedRow.unitPrice
        const updatedRows = [...tableItems]
        const rowIndex = rows.findIndex((row) => row.id === updatedRow.id)
        updatedRows[rowIndex] = updatedRow
        setRows(updatedRows)
        handleTableItems(updatedRows)
        return updatedRow
    }

    const columns = [
        { field: 'nationalStockNumber', headerName: 'NSN', width: 100 },
        { field: 'partNumber', headerName: 'Part #', width: 100 },
        { field: 'serialNumber', headerName: 'Serial #', width: 100 },
        { field: 'nomenclature', headerName: 'Nomenclature', width: 140 },
        { field: 'unitOfIssue', headerName: 'UI', width: 60 },
        { 
            field: 'selectedQuantity', 
            headerName: 'Quantity', 
            type: 'number', 
            width: 80, 
            editable: true,
            preProcessEditCellProps: (params) => {
                const isError = (params.props.value > params.row.currentBalance) || (params.props.value < 0)
                return { ...params.props, error: isError }
            },
        },
        { field: 'currentBalance', headerName: 'Current Balance', align: 'right', width: 100 },
        { field: 'conditionCode', headerName: 'CC', width: 50 },
        { 
            field: 'unitPrice', 
            headerName: 'Unit Price', 
            width: 120,
            valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
            valueFormatter: ({ value }) =>
                value != null
                ? "$ " +
                    Intl.NumberFormat("en-US", {
                    maximumFractionsDigits: 2,
                    minimumFractionDigits: 2,
                    }).format(value)
                : "", 
        },
        { 
            field: 'totalCost', 
            headerName: 'Total Cost', 
            width: 120,
            valueGetter: ({ value }) => value && parseFloat(value).toFixed(2),
            valueFormatter: ({ value }) =>
                value != null
                ? "$ " +
                    Intl.NumberFormat("en-US", {
                    maximumFractionsDigits: 2,
                    minimumFractionDigits: 2,
                    }).format(value)
                : "", 
        },
        { field: 'location', headerName: 'Location', width: 120 },
        { field: 'propertyNumber', headerName: 'Property #', width: 100 },
        {
            field: "remove",
            headerName: "Remove",
            minWidth: 80,
            align: "left",
            headerAlign: "center",
            renderCell: (params) => {
                return (
                <div className="actions-buttons">
                    <RemoveCircleIcon
                        className="delete-btn"
                        onClick={() => {
                            handleTransferItems(params.id, "remove")
                            handleTableItems(rows.filter((item) => item.id !== params.id))
                        }}
                    />
                </div>
                )
            },
        },
    ]

    return (
        <div>
            <div>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
            </div>
            <Grid
                container
                columns={11}
            >
                <Grid 
                    item 
                    xs={0}
                    sx={{ paddingTop: "30px !important" }}
                >
                    <Typography 
                        sx={{ fontFamily: "Merriweather" }}
                    >
                        Select the items for this transfer and set quantities for non-serialized items:
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={11}
                    sx={{ paddingTop: "20px !important" }}
                >
                    <StyledDataGrid>
                        <DataGridPremium
                            columns={columns}
                            rows={rows}
                            disableRowSelectionOnClick
                            checkboxSelection={true}
                            rowSelectionModel={rowSelectionModel}
                            onRowSelectionModelChange={onRowSelectionModelChange}
                            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row")}
                            isCellEditable={(params) => !params.row.serialNumber || params.row.serialNumber === "NA"}
                            processRowUpdate={handleRowUpdate}
                            onProcessRowUpdateError={(e) => console.log("Error", e)}
                            sx={{
                                boxShadow: 3,
                                border: "1px solid",
                                borderColor: "rgba(16, 80, 117, 0.5)",
                                "& .even-row.MuiDataGrid-row": {
                                backgroundColor: "rgba(219, 231, 229, 0.35)",
                                "&:hover, &.Mui-hovered": {
                                    backgroundColor: "rgba(84, 187, 253, 0.1)",
                                    "@media (hover: none)": {
                                    backgroundColor: "transparent",
                                    },
                                },
                                },
                            }}
                        />
                    </StyledDataGrid>
                </Grid>
                <Grid
                    item
                    xs={11}
                    sx={{ paddingTop: "20px !important" }}
                >
                    <Divider />
                </Grid>
            </Grid>
        </div>
    )
}

export default TransferItemsTable