// ** MUI
import { Box, Grid } from "@mui/material"
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

// ** Custom
import { style } from "../utils"

const FailedLoginDialog = () => {
    return (
        <Box sx={style}>
            <Grid
                container
                spacing={2}
                columns={11}
            >
                <Grid
                    item
                    xs={11}
                >
                    <HighlightOffIcon color="error" style={{ fontSize: 50}} />
                </Grid>
            </Grid>
            <Grid
                item
                xs={11}
            >
                Sorry, we weren't able to log you in. Please contact an administrator for assistance.
            </Grid>
        </Box>
    )
}

export default FailedLoginDialog