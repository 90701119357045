import { useEffect, useState, useContext } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

// ** Toastify
import "react-toastify/dist/ReactToastify.css"

// ** MUI
import { IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "../components/styles/AdditionalColumnStyles.css"

// ** Custom
import { useAuth } from "react-oidc-context"
import { CustomStripedGrid } from "../components/CustomStripedGrid"
import LoadingBackdrop, {
  getProgramName,
  getWarehouseBuildingNumber,
  propertyFormatter,
  notify,
  timeRegexValidation,
  getTimezoneFormattedDate,
} from "../utils"
import { numberInUOM } from "../context/variables"
import Header from "../layout/Header"

const HistoricChangesView = ({ leftMenuDrawerOpen }) => {
  const context = useContext(AppContext)
  const params = useParams()
  const navigate = useNavigate()
  const { id } = params
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState("")
  const auth = useAuth()
  const title = "Historic Changes"

  useEffect(() => {
    fetchData(id)
  }, [])

  useEffect(() => {
    if (data) {
      setLoaded(true)
    }
  }, [data])

  const fetchData = async (id) => {
    try {
      let inventoryRecordChanges = await apiCalls.getHistoricChangesByInventoryRecordId(id)
      setData(inventoryRecordChanges.data)
    } catch (error) {
      console.error("error", error)
      if (error.response?.status === 401) {
        auth.signinRedirect()
      } else {
        notify("error", "There was a problem loading the historic changes. Please try again.")
      }
    }
  }

  const valueReformatter = (value) => {
    // Reformat GUID Program/Warehouse values to their corresponding names.
    let pattern = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi
    if (value.match(pattern)) {
      let programName = getProgramName(value, context.allProgramsData)
      let warehouseBuildingNumber = getWarehouseBuildingNumber(value, context.warehouseData)
      return programName != null ? programName : warehouseBuildingNumber
    }

    // Reformat UOMs that have a number in them.
    if (value in numberInUOM) {
      return numberInUOM[value]
    }

    return value
  }

  const columns = [
    {
      field: "dateTime",
      headerName: "Date/Time",
      width: 170,
      valueFormatter: (params) => getTimezoneFormattedDate(params?.value, true),
    },
    {
      field: "property",
      headerName: "Property",
      width: 300,
      valueFormatter: (params) => propertyFormatter(params),
    },
    {
      field: "previousValue",
      headerName: "Previous Value",
      width: 400,
      valueFormatter: (params) => {
        if (timeRegexValidation(params.value)) {
          return getTimezoneFormattedDate(params?.value, false)
        } else {
          return valueReformatter(params.value)
        }
      },
    },
    {
      field: "newValue",
      headerName: "New Value",
      width: 400,
      valueFormatter: (params) => {
        if (timeRegexValidation(params.value)) {
          return getTimezoneFormattedDate(params?.value, false)
        } else {
          return valueReformatter(params.value)
        }
      },
    },
    { field: "username", headerName: "Edited By", width: 100 },
  ]

  const tableRows = () => {
    return data.map((item) => {
      return {
        id: uuidv4(),
        dateTime: item.time,
        property: item.difference.memberPath,
        previousValue: item.difference.value1,
        newValue: item.difference.value2,
        username: item.username,
      }
    })
  }

  if (loaded) {
    return (
      <>
        <div
          className="back-btn-wrapper"
          style={{ marginBottom: 20 }}
        >
          <IconButton
            onClick={() => navigate(-1)}
            size="large"
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </div>
        <Header title={title} />
        <CustomStripedGrid
          initialState={{
            sorting: {
              sortModel: [{ field: "dateTime", sort: "desc" }],
            },
          }}
          data={tableRows()}
          columns={columns}
          title={title}
        />
      </>
    )
  } else return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
}

export default HistoricChangesView
