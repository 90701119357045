import { useState } from "react"

// ** MUI
import { Box, Button, Divider, Grid, IconButton, Modal } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { getBase64, notify } from "../utils"
import DocumentsInputForm from "./DocumentsInputForm"

export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  borderRadius: 5,
  boxShadow: 24,
  p: 4,
}

export const DocumentAttachmentModal = ({
  shouldOpen,
  handleClose,
  selectedRowsIds,
  programName,
  documentRows,
  handleDocumentRows,
  clearSelectedRows,
}) => {
  const [open, setOpen] = useState(shouldOpen)
  const [buttonPressed, setButtonPressed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const handleDocumentAttachment = async () => {
    setButtonPressed(true)
    setIsLoading(true)
    let documentIds = []
    await Promise.all(
      documentRows.map(async (el) => {
        await new Promise(getBase64(el.fileObject)).then(async (result) => {
          let basicRequest = {
            bucketName: "awims",
            objectName: el.fileName,
            filePath: `${programName}/Inventory Documents`,
            file: result,
          }

          try {
            let response = await apiCalls.putFile(basicRequest)
            documentIds.push(response.data.result.value.id)
          } catch (error) {
            console.error("Error:", error)
          }
        })
      })
    )
      .then(async () => {
        // Once document(s) are added, create relationship between record and document(s).
        await Promise.all(
          documentIds.map((docId) => {
            return selectedRowsIds.map(async (el) => {
              let request = {
                inventoryRecordId: el,
                documentId: docId,
              }

              await apiCalls.postRecord(request, "AttachmentRecords")
            })
          })
        )

        handleClose(false)
        notify("success", "Files were successfully attached to selected items.")
        clearSelectedRows()
      })
      .catch((error) => {
        notify("error", "Something went wrong when attaching the files.")
        console.error("Error in handleDocumentAttachment():", error)
      })
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* CLOSE ICON */}
        <Grid
          container
          spacing={2}
        >
          <IconButton
            onClick={() => handleClose(false)}
            disabled={buttonPressed}
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid
          container
          spacing={2}
        >
          {/* TOP DIVIDER */}
          <Grid
            item
            xs={12}
            sx={{ paddingTop: "15px !important" }}
          >
            <Divider className="divider-title">Attach Document(s)</Divider>
          </Grid>

          {/* TEXT */}
          <Grid
            item
            xs={12}
            sx={{ textAlign: "left" }}
          >
            Attach any number of documents in the table below to the records previously selected in the <i>Inventory Records</i> table:
          </Grid>

          {/* TABLE */}
          <Grid
            item
            xs={12}
          >
            <DocumentsInputForm
              operation="multipleRecordUpload"
              type="inventoryRecord"
              programName={programName}
              recordId={null}
              finalDocumentRows={documentRows}
              handleFinalDocumentRows={handleDocumentRows}
              handleFileConversion={getBase64}
              isLoading={isLoading}
            />
          </Grid>

          {/* BUTTON */}
          <Grid
            item
            xs={10}
          />
          <Grid
            item
            xs={2}
          >
            <Button
              variant="contained"
              onClick={() => handleDocumentAttachment()}
              disabled={!documentRows.length || buttonPressed}
            >
              Submit
            </Button>
          </Grid>

          {/* BOTTOM DIVIDER */}
          <Grid
            item
            xs={12}
          >
            <Divider />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
