import { useContext, useState } from "react"

// ** MUI
import { Divider, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material"
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow"

// ** Context
import AppContext from "../../AppContext"

const ProgramSelectionForm = ({ transferItems, startProgramId, endProgramId, handleSetValueFromStep, errors, handleValidate }) => {
  const context = useContext(AppContext)
  const [destinationPrograms, setDestinationPrograms] = useState(context.allProgramsData)

  const startProgramSelectOptions = transferItems?.map((el) => {
    return (
      <MenuItem
        key={el.id}
        value={el.id}
      >
        {el.name}
      </MenuItem>
    )
  })

  const destinationProgramsOptions = destinationPrograms?.map((el) => {
    return (
      <MenuItem
        key={el.id}
        value={el.id}
      >
        {el.name}
      </MenuItem>
    )
  })

  return (
    <div>
      <Grid
        container
        spacing={2}
        columns={11}
      >
        <Grid
          item
          xs={0.5}
        />
        <Grid
          item
          xs={4.5}
          sx={{ paddingTop: "35px !important" }}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel
              shrink
              id="start-program-name"
              required
              error={errors.startProgram}
            >
              Start Program
            </InputLabel>
            <Select
              name="startProgram"
              required
              labelId="start-program-select-label"
              id="start-program-select"
              value={startProgramId}
              label="Start Program"
              onChange={(e) => handleSetValueFromStep(e)}
              onBlur={handleValidate}
              error={errors.startProgram}
              input={
                <OutlinedInput
                  notched
                  required
                  label="Start Program"
                />
              }
            >
              <MenuItem
                key="select-start-program"
                value=""
                disabled
              >
                Select
              </MenuItem>
              {startProgramSelectOptions}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={1}
          sx={{ paddingTop: "42px !important" }}
        >
          <DoubleArrowIcon
            fontSize="large"
            sx={{ color: "grey" }}
          />
        </Grid>
        <Grid
          item
          xs={4.5}
          sx={{ paddingTop: "35px !important" }}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel
              shrink
              id="end-program-name"
              required
              error={errors.endProgram}
            >
              Destination Program
            </InputLabel>
            <Select
              name="endProgram"
              required
              labelId="end-program-select-label"
              id="end-program-select"
              value={endProgramId}
              label="Destination Program"
              onChange={(e) => handleSetValueFromStep(e)}
              onBlur={handleValidate}
              error={errors.endProgram}
              input={
                <OutlinedInput
                  notched
                  required
                  label="Destination Program"
                />
              }
            >
              <MenuItem
                key="select-end-program"
                value=""
                disabled
              >
                Select
              </MenuItem>
              {destinationProgramsOptions}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={11}
          sx={{ paddingTop: "20px !important" }}
        >
          <Divider />
        </Grid>
      </Grid>
    </div>
  )
}

export default ProgramSelectionForm
