import { useContext, useEffect, useState } from "react"
import Header from "../layout/Header"
import { useLocation } from "react-router-dom"

// ** React Router
import { useParams } from "react-router-dom"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Custom
import AppContext from "../AppContext"
import { CustomStripedGrid } from "../components/CustomStripedGrid"
import LoadingBackdrop from "../utils"

const ListView = ({ leftMenuDrawerOpen }) => {
  const context = useContext(AppContext)
  const params = useParams()
  const { id } = params
  const [loaded, setLoaded] = useState(false)
  const [listData, setListData] = useState("")
  const [listName, setListName] = useState("")
  const location = useLocation()

  useEffect(() => {
    if (location.state.isFromAP) {
      fetchAPListData(id)
    } else {
      fetchWHListData(id)
    }
  }, [])

  useEffect(() => {
    if (listData) {
      setLoaded(true)
    }
  }, [listData])

  const fetchAPListData = async (id) => {
    // does data need to be filtered by activeState here
    try {
      let APwarehousesList = await apiCalls.getWarehousesListByArmyProgramId(id)
      let listName = APwarehousesList.data.value[0].name
      let initialListData = APwarehousesList.data.value[0].warehouseList
      let listDataToDisplay = initialListData.map((listObj) => listObj.warehouse)
      setListData(listDataToDisplay)
      setListName(listName)
    } catch (error) {
      console.error("error", error)
    }
  }

  const fetchWHListData = async (id) => {
    try {
      let WHarmyProgramsList = await apiCalls.getArmyProgramsListByWarehouseId(id)
      let listName = WHarmyProgramsList.data.value[0].buildingNumber
      let initialListData = WHarmyProgramsList.data.value[0].armyProgramList
      let listDataToDisplay = initialListData.map((listObj) => listObj.armyProgram)
      setListData(listDataToDisplay)
      setListName(listName)
    } catch (error) {
      console.error("error", error)
    }
  }

  let entityToDisplay = location.state.isFromAP ? "warehouses" : "army_programs"

  let updateColumns = context[`${entityToDisplay}`].columns.filter((el) => el.field !== "actions")

  let title = `${listName} ${location.state.isFromAP ? "Warehouses" : "Programs"}`

  if (loaded) {
    return (
      <>
        <Header title={title} />
        <CustomStripedGrid
          initialState={{
            sorting: {
              sortModel: [{ field: entityToDisplay === "army_programs" ? "name" : "buildingNumber", sort: "asc" }],
            },
          }}
          data={listData}
          columns={updateColumns}
          title={title}
          pathname={location.state.pathname}
        />
      </>
    )
  } else {
    return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
  }
}

export default ListView
