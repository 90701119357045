import { useNavigate } from "react-router-dom"

// ** Toastify
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// ** MUI
import { Container, IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

// ** Styles
import "../components/styles/CustomFormStyles.css"

// ** Custom
import Header from "../layout/Header"
import DC001CustomForm from "../components/DC001CustomForm.jsx"

const RequestSummaryView = ({ cartItems, handleDeleteItemFromCart, handleSubmitForm, handleDeleteAllCartItems }) => {
  const navigate = useNavigate()
  const title = "Request Summary"

  return (
    <Container
      className="form-bg"
      // sx={{ minHeight: "100vh", width: "100%" }}
    >
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
      <div className="back-btn-wrapper">
        <IconButton
          onClick={() => navigate(-1)}
          size="large"
        >
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
      </div>
      <div className="header-padding">
        <Header title={title} />
      </div>
      <DC001CustomForm
        cartItems={cartItems}
        handleDeleteItemFromCart={handleDeleteItemFromCart}
        handleDeleteAllCartItems={handleDeleteAllCartItems}
        handleSubmitForm={handleSubmitForm}
      />
    </Container>
  )
}

export default RequestSummaryView
