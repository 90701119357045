import { useContext, useState, useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"

// ** Toastify
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// ** MUI
import { Box, Button, IconButton } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "./styles/CustomFormStyles.css"

// ** Custom
import FilterProgramsSelect from "./FilterProgramsSelect"
import Header from "../layout/Header"
import CustomProgressDialog from "../components/CustomProgressDialog"
import CSVUploadErrorTable from "./CSVUploadErrorTable"
import { notify } from "../utils"

const ImportForm = ({ handleSelect, selectedGlobalProgramId }) => {
  let context = useContext(AppContext)
  const navigate = useNavigate()
  const [selectedImportProgramId, setSelectedImportProgramId] = useState("")
  const csvFile = useRef(null)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState("")
  const [hasMissingHeaders, setHasMissingHeaders] = useState(false)
  const [missingHeaders, setMissingHeaders] = useState([])

  // NOTE: Please do not remove!
  // Websocket Variables
  // const [startPing, setStartPing] = useState(false)
  // const MINUTE_MS = 5000

  // useEffect(() => {
  //   let connectionId = Math.floor(Math.random() * 100000)
  //   startNewConnection(connectionId)
  // }, [])

  useEffect(() => {
    if (selectedGlobalProgramId) {
      setSelectedImportProgramId(selectedGlobalProgramId)
    }
  }, [selectedGlobalProgramId])

  // Invoke Hub method, "PingServerWithMessage" to ping the server.
  // const sendMessage = async() => {
  //   try {
  //     await webSocketConnection.invoke("PingServerWithMessage")
  //   } catch(error) {
  //     console.error("Error:", error)
  //   }
  // }

  // useEffect(() => {
  //   if (webSocketConnection) {
  //     const interval = setInterval(async () => {
  //       await sendMessage()
  //     }, MINUTE_MS)

  //     return () => clearInterval(interval)
  //   }

  // }, [startPing])

  const handleFileUpload = async (file) => {
    // Clear errors.
    setHasMissingHeaders(false)
    setMissingHeaders([])
    setErrors("")

    if (file?.type === "text/csv") {
      setIsLoading(true)
      csvFile.current = file
      const selectedFileName = csvFile.current.name
      const fileReader = new FileReader()
      fileReader.onload = async () => {
        const formData = new FormData()
        formData.append("FormFile", new Blob([csvFile.current], { type: "text/csv" }), selectedFileName)
        try {
          // Start ping-ponging with server to keep connection open.
          //setStartPing(true)

          // Validate, then add temp file and start Hangfire task of uploading data.
          await apiCalls.addTempFile(formData).then(async (response) => {
            if (response.status === 200) {
              let body = {
                fileName: selectedFileName,
                programId: selectedImportProgramId,
                userEmail: context.userEmail,
              }

              await apiCalls.uploadCSVWithHangfire(body)
            }
          })

          setIsLoading(false)
          //await closeConnection(webSocketConnection)
          notify("success", "The CSV is being uploaded. An email will be sent once the task is completed.")
          setTimeout(() => {
            navigate(`/inventory_records`)
          }, 4000)
        } catch (error) {
          console.error("Error:", error)
          setIsLoading(false)

          if (error.response.data.hasOwnProperty("missingHeaders")) {
            setHasMissingHeaders(true)

            // Add space before each header for better visibility.
            setMissingHeaders(error.response.data.missingHeaders.join(", "))
          }
          setErrors(error.response.data)

          notify("error", "There was a problem uploading your CSV data.")
          csvFile.current = null
        }
      }
      fileReader.readAsText(csvFile.current)
    } else {
      setIsLoading(false)
      notify("error", `File type not supported. Please upload a CSV file.`)
      csvFile.current = null
    }
  }

  return (
    <form
      encType="multipart/form-data"
      className="form-bg"
    >
      <div>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
      <div
        className="back-btn-wrapper"
        style={{ marginLeft: 25 }}
      >
        <IconButton
          onClick={() => navigate(-1)}
          size="large"
        >
          <ArrowBackIcon fontSize="inherit" />
        </IconButton>
      </div>
      <Box className="form-grid">
        <Header title="Upload CSV" />
        {isLoading && (
          <CustomProgressDialog
            open={isLoading}
            progress={null}
            title="Validating Data..."
          />
        )}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <FilterProgramsSelect
            handleSelect={handleSelect}
            selectedGlobalProgramId={selectedGlobalProgramId}
          />
          <Button
            variant="contained"
            component="label"
            disabled={!selectedImportProgramId}
            sx={{
              color: "white",
              fontWeight: "bold",
              width: "200px",
              backgroundColor: "#105075",
              border: "2px solid #646464",
              marginTop: "15px",
            }}
          >
            Upload File
            <input
              type="file"
              accept=".csv"
              hidden
              onChange={(e) => {
                handleFileUpload(e.target.files[0])
              }}
            />
          </Button>
        </Box>
        {errors && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <i style={{ color: "red", marginBottom: "10px", marginTop: "20px" }}>
              {hasMissingHeaders
                ? `The CSV is missing the following headers: ${missingHeaders}. Please add or make corrections to the headers.`
                : `Unable to upload your CSV data. Please see below for errors found in the file:`}
            </i>
            {hasMissingHeaders ? null : <CSVUploadErrorTable rows={errors} />}
          </Box>
        )}
      </Box>
    </form>
  )
}

export default ImportForm
