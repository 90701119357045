import { useState } from "react"

// ** MUI
import {
  Box,
  Button,
  Divider, 
  FormControl,
  FormControlLabel,
  IconButton,
  Grid,
  Modal,
  Radio,
  RadioGroup,
} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'

// ** Custom
import { style } from "../utils"

export const PercentageSelectionModal = ({ shouldOpen, handleFetchMonthlyPercentageOfRecords }) => {
  const [open, setOpen] = useState(shouldOpen)
  const [value, setValue] = useState("")
  const [isDisabled, setIsDisabled] = useState(true)

  const handleRadioChange = (event) => {
    if (event.target.value !== null) {
      setIsDisabled(false)
    }
    
    setValue(event.target.value)
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* CLOSE ICON */}
        <Grid
          container
          spacing={2}
        >
          <IconButton
            onClick={() => handleFetchMonthlyPercentageOfRecords(false)}
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid
          container
          spacing={2}
          columns={11}
        >
          {/* TOP DIVIDER */}
          <Grid
            item
            xs={11}
            sx={{ paddingTop: "25px !important" }}
          >
            <Divider />
          </Grid>

          {/* TEXT */}
          <Grid
            item
            xs={11}
            sx={{ fontFamily: "Merriweather", textAlign:'center', fontSize:'medium'}}
          >  
            Select a percentage of records to inventory:
          </Grid>

          {/* RADIO BUTTONS */}
          <Grid
            item
            xs={11}
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ mb:2 }}
                value={value}
                onChange={handleRadioChange}
              >
                <FormControlLabel value="10" control={<Radio />} label="10%" />
                <FormControlLabel value="100" control={<Radio />} label="100%" />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* BUTTON */}
          <Grid 
            item 
            xs={8} 
          />
          <Grid
            item
            xs={3}
          >
            <Button
              variant="contained"
              disabled={isDisabled}
              onClick={() => handleFetchMonthlyPercentageOfRecords(value)}
            >
              Submit
            </Button>
          </Grid>

          {/* BOTTOM DIVIDER */}
          <Grid 
            item 
            xs={11}
          >
            <Divider />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
