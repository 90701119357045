import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import { BrowserRouter } from "react-router-dom"
import { AuthProvider } from "react-oidc-context"
import { WebStorageStateStore } from "oidc-client-ts"

const SERVER_URL = process.env.REACT_APP_SERVER_URL ? process.env.REACT_APP_SERVER_URL : window.location.origin
const AUTHORITY_URL = process.env.REACT_APP_AUTHORITY_URL ? process.env.REACT_APP_AUTHORITY_URL : "https://keycloak.sigmatech.dev/auth/realms/awims"

const oidcConfig = {
  authority: `${AUTHORITY_URL}/`,
  client_id: "awims",
  redirect_uri: `${SERVER_URL}/`,
  loadUserInfo: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  onSigninCallback() {
    window.history.replaceState({}, document.title, window.location.pathname)
  },
}

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <AuthProvider {...oidcConfig}>
    <BrowserRouter basename="/">
      <App />
    </BrowserRouter>
  </AuthProvider>
)
