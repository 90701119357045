import { useContext, useEffect, useState } from "react"

// ** MUI
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "../components/styles/CustomFormStyles.css"

// ** Custom
import { getProgramName, getFormattedDate, SetTimeAndOffset } from "../utils"
import CartItemsGrid from "./CartItemsGrid"

const DC001CustomForm = ({ cartItems, handleDeleteItemFromCart, handleSubmitForm, handleDeleteAllCartItems }) => {
  const context = useContext(AppContext)
  const [requestDate, setRequestDate] = useState(SetTimeAndOffset(new Date().toISOString()))
  const [requestedBy, setRequestedBy] = useState("")
  const [programId, setProgramId] = useState("")
  const [pocName, setPocName] = useState("")
  const [pocEmail, setPocEmail] = useState("")
  const [pocPhone, setPocPhone] = useState("")
  const [priority, setPriority] = useState("")
  const [requiredDeliveryDate, setRequiredDeliveryDate] = useState("")
  const [requestType, setRequestType] = useState("")
  const [specialInstructions, setSpecialInstructions] = useState("")
  const [activityName, setActivityName] = useState("")
  const [address, setAddress] = useState("")
  const [cityStateZip, setCityStateZip] = useState("")
  const [DODAAC, setDODAAC] = useState("")
  const [cartItemsToDisplay, setCartItemsToDisplay] = useState("")
  const [errors, setErrors] = useState("")
  const [estimatedTotalPrice, setEstimatedTotalPrice] = useState("")

  useEffect(() => {
    setRequestedBy(context.fullName)
  }, [requestedBy])

  useEffect(() => {
    let filteredItemsQtyWiped = [...cartItems]
      .filter((item) => programId === item.managementRecordArmyProgramId)
      .map((item) => {
        if (!item.serialNumber || item.serialNumber === "NA") {
          return {
            ...item,
            quantity: 0,
          }
        } else {
          return {
            ...item,
          }
        }
      })

    setCartItemsToDisplay(filteredItemsQtyWiped)
  }, [programId, cartItems])

  useEffect(() => {
    const estimatedTotalPrice = [...cartItemsToDisplay].reduce((acc, item) => {
      acc += item.totalCost
      return acc
    }, 0)
    setEstimatedTotalPrice(estimatedTotalPrice)
    if (!cartItemsToDisplay.length) {
      setProgramId("")
    }
  }, [cartItemsToDisplay])

  const handleCartItemsDisplayed = (updatedItems) => {
    setCartItemsToDisplay(updatedItems)
  }

  const formatCartItemPrograms = (cartItems) => {
    let cartItemProgramIds = [...cartItems].reduce((acc, item) => {
      if (!acc.includes(item.managementRecordArmyProgramId)) {
        acc.push(item.managementRecordArmyProgramId)
      }
      return acc
    }, [])

    const programDataToIterate = context.allProgramsData
    const cartPrograms = new Set(cartItemProgramIds)
    const cartProgramData = programDataToIterate?.filter((el) => cartPrograms.has(el.id.toString()))
    return cartProgramData
  }

  const programSelectOptions = formatCartItemPrograms(cartItems)?.map((program) => {
    return (
      <MenuItem
        key={program.id}
        value={program.id}
      >
        {program.name}
      </MenuItem>
    )
  })

  const isSpecialInstructionsRequired = requestType.includes("Movement") || requestType.includes("Pickup")

  const requestTypes = [
    "Movement Request - Local Delivery",
    "Pickup Request - Local Pickup only",
    "Pull Request - Assets requested to be shipped",
    "Turn-in Request - Assets requested to be Turned-in/DRMO",
  ]

  const requestTypeOptions = requestTypes.map((type) => {
    return (
      <MenuItem
        key={type}
        value={type}
      >
        {type}
      </MenuItem>
    )
  })

  const priorities = ["High - (1-2 Day Shipping)", "Medium - (3-5 Day Shipping)", "Standard - (5-7 Day Shipping)"]

  const priorityOptions = priorities.map((priority) => {
    return (
      <MenuItem
        key={priority}
        value={priority}
      >
        {priority}
      </MenuItem>
    )
  })

  const handleFormattingGridItems = async () => {
    return [...cartItemsToDisplay].map((item) => {
      return {
        inventoryRecordId: item.id,
        nationalStockNumber: item.nationalStockNumber,
        hazardousMaterielIndicatorCode: item.hazardousMaterielIndicatorCode,
        partNumber: item.partNumber,
        serialNumber: item.serialNumber,
        nomenclature: item.nomenclature,
        unitOfIssue: item.unitOfIssue,
        quantity: item.quantity,
        conditionCode: item.conditionCode,
        unitPrice: item.unitPrice,
        totalCost: item.totalCost,
        location: item.location,
        endBalance: 0,
        propertyNumber: item.propertyNumber,
      }
    })
  }

  const handleFormattingForSubmit = async () => {
    const requestCartItems = await handleFormattingGridItems()
    const formObjToPost = {
      requestDate: requestDate,
      requestedBy: requestedBy,
      programId: programId,
      programName: getProgramName(programId, context.allProgramsData),
      requestType: requestType,
      pocName: pocName,
      pocEmail: pocEmail,
      pocPhone: pocPhone,
      requiredDeliveryDate: requiredDeliveryDate,
      priority: priority,
      specialInstructions: specialInstructions,
      estimatedTotalPrice: estimatedTotalPrice,
      dodAac_Uic: DODAAC,
      address: address,
      activityName: activityName,
      city_State_Zip: cityStateZip,
      requestItems: requestCartItems,
    }
    await validateFormFields(formObjToPost)

    await handleSubmitForm(formObjToPost, cartItemsToDisplay)
  }

  const validateFormFields = async (formObj) => {
    const formObjKeys = Object.keys(formObj)
    formObjKeys.forEach((key) => {
      let isSpecialInstructions = key === "specialInstructions"
      let isNotSIAndNoValue = !isSpecialInstructions && !formObj[key]
      if ((isSpecialInstructionsRequired && isSpecialInstructions && !formObj[key]) || isNotSIAndNoValue) {
        setErrors((prevState) => ({ ...prevState, [key]: true }))
      }
      if ((isSpecialInstructions && !isSpecialInstructionsRequired && !formObj[key]) || formObj[key]) {
        setErrors((prevState) => ({ ...prevState, [key]: false }))
      }
    })
  }

  const handleValidate = (e) => {
    let isSpecialInstructions = e.target.name === "specialInstructions"
    let isNotSIAndNoValue = !isSpecialInstructions && !e.target.value
    if ((isSpecialInstructionsRequired && isSpecialInstructions && !e.target.value) || isNotSIAndNoValue) {
      setErrors((prevState) => {
        return {
          ...prevState,
          [e.target.name]: true,
        }
      })
    } else if ((isSpecialInstructions && !isSpecialInstructionsRequired && !e.target.value) || e.target.value) {
      setErrors((prevState) => {
        return {
          ...prevState,
          [e.target.name]: false,
        }
      })
    }
  }

  let conditionToDisplayMessage = !cartItemsToDisplay.length && !programId

  return (
    <div style={{ width: "100%", minHeight: 500 }}>
      <Grid
        container
        spacing={1}
        columns={16}
        justifyContent="space-evenly"
        alignContent="space-evenly"
        sx={{ width: "100%", minHeight: "45vh" }}
      >
        <Grid
          item
          xs={3}
        >
          <FormControl
            required
            sx={{ minWidth: "100%" }}
          >
            <InputLabel
              error={errors.requestDate}
              shrink
            >
              Request Date
            </InputLabel>
            <OutlinedInput
              name="requestDate"
              id="request-date"
              margin="dense"
              notched
              label="Request Date"
              type="date"
              value={getFormattedDate(requestDate.replace(" ", "T"))}
              onChange={(e) => setRequestDate(e.target.value + " " + SetTimeAndOffset())}
              required
              error={errors.requestDate}
              onBlur={handleValidate}
            />
            {errors.requestDate && <FormHelperText error>* Required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={4}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              name="requestedBy"
              id="requested-by"
              label="Requested By"
              type="text"
              value={requestedBy}
              onChange={(e) => setRequestedBy(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              error={errors.requestedBy}
              onBlur={handleValidate}
              helperText={errors.requestedBy && "* Required"}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={8}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel
              shrink
              id="request-type"
              required
              error={errors.requestType}
            >
              Request Type
            </InputLabel>
            <Select
              name="requestType"
              labelId="request-select-label"
              id="request-select"
              value={requestType}
              label="Request Type"
              required
              onChange={(e) => setRequestType(e.target.value)}
              onBlur={handleValidate}
              error={errors.requestType}
              input={
                <OutlinedInput
                  notched
                  label="Request Type"
                  error={errors.requestType}
                />
              }
            >
              <MenuItem
                key="select-request"
                value=""
                disabled
              >
                Select
              </MenuItem>
              {requestTypeOptions}
            </Select>
            {errors.requestType && <FormHelperText error>* Required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              name="pocName"
              id="poc-name"
              label="POC Name"
              type="text"
              value={pocName}
              onChange={(e) => setPocName(e.target.value)}
              InputLabelProps={{ shrink: true }}
              error={errors.pocName}
              onBlur={handleValidate}
              helperText={errors.pocName && "* Required"}
              required
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              name="pocEmail"
              id="poc-email"
              label="POC Email"
              type="text"
              value={pocEmail}
              onChange={(e) => setPocEmail(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              error={errors.pocEmail}
              onBlur={handleValidate}
              helperText={errors.pocEmail && "* Required"}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              name="pocPhone"
              id="poc-phone"
              label="POC Phone"
              type="text"
              value={pocPhone}
              onChange={(e) => setPocPhone(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              error={errors.pocPhone}
              onBlur={handleValidate}
              helperText={errors.pocPhone && "* Required"}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel
              shrink
              id="program-name"
              required
              error={errors.programName}
            >
              Program Name
            </InputLabel>
            <Select
              name="programName"
              required
              labelId="program-select-label"
              id="program-select"
              value={programId}
              // defaultValue=""
              label="Program Name"
              onChange={(e) => setProgramId(e.target.value)}
              onBlur={handleValidate}
              error={errors.programName}
              input={
                <OutlinedInput
                  notched
                  label="Program Name"
                />
              }
            >
              <MenuItem
                key="select-program"
                value=""
                disabled
              >
                Select
              </MenuItem>
              {programSelectOptions}
            </Select>
            {(errors.programName && <FormHelperText error>* Please select a Program.</FormHelperText>) ||
              (conditionToDisplayMessage && <FormHelperText primary>* Please select a Program.</FormHelperText>)}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel
              shrink
              id="priority"
              required
              error={errors.priority}
            >
              Priority
            </InputLabel>
            <Select
              name="priority"
              labelId="priority-select-label"
              id="priority-select"
              value={priority}
              label="Priority"
              required
              onChange={(e) => setPriority(e.target.value)}
              onBlur={handleValidate}
              error={errors.priority}
              input={
                <OutlinedInput
                  notched
                  label="Priority"
                />
              }
            >
              <MenuItem
                key="select-priority"
                value=""
                disabled
              >
                Select
              </MenuItem>
              {priorityOptions}
            </Select>
            {errors.priority && <FormHelperText error>* Required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <InputLabel
              shrink
              required
              error={errors.requiredDeliveryDate}
            >
              Required Delivery Date
            </InputLabel>
            <OutlinedInput
              name="requiredDeliveryDate"
              id="required-delivery-date"
              margin="dense"
              notched
              required
              label="Required Delivery Date"
              type="date"
              value={requiredDeliveryDate}
              onChange={(e) => setRequiredDeliveryDate(e.target.value)}
              error={errors.requiredDeliveryDate}
              onBlur={handleValidate}
            />
            {errors.requiredDeliveryDate && <FormHelperText error>* Required</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              name="specialInstructions"
              id="special-instructions"
              label="Special Instructions"
              type="text"
              value={specialInstructions}
              onChange={(e) => setSpecialInstructions(e.target.value)}
              InputLabelProps={{ shrink: true }}
              placeholder="Local Pickup/Local Delivery Address"
              required={isSpecialInstructionsRequired}
              error={errors.specialInstructions}
              onBlur={handleValidate}
              helperText={errors.specialInstructions && "* Required"}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              id="dodAac_Uic"
              label="DODAAC/UIC"
              name="dodAac_Uic"
              type="text"
              value={DODAAC}
              onChange={(e) => setDODAAC(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              error={errors.dodAac_Uic}
              onBlur={handleValidate}
              helperText={errors.dodAac_Uic && "* Required"}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              id="address"
              name="address"
              label="Address"
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              error={errors.address}
              onBlur={handleValidate}
              helperText={errors.address && "* Required"}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              name="activityName"
              id="activityName"
              label="Activity Name"
              type="text"
              value={activityName}
              onChange={(e) => setActivityName(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              error={errors.activityName}
              onBlur={handleValidate}
              helperText={errors.activityName && "* Required"}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <FormControl sx={{ minWidth: "100%" }}>
            <TextField
              name="city_State_Zip"
              id="city_State_Zip"
              label="City, State, Zip"
              type="text"
              value={cityStateZip}
              onChange={(e) => setCityStateZip(e.target.value)}
              InputLabelProps={{ shrink: true }}
              required
              error={errors.city_State_Zip}
              onBlur={handleValidate}
              helperText={errors.city_State_Zip && "* Required"}
            />
          </FormControl>
        </Grid>
        <Grid
          item
          xs={5}
        >
          <FormControl
            disabled
            sx={{ minWidth: "100%" }}
          >
            <TextField
              name="estimatedTotalPrice"
              id="estimated_total_price"
              label="Estimated Total Price"
              type="text"
              value={Intl.NumberFormat("en-US", {
                maximumFractionsDigits: 2,
                minimumFractionDigits: 2,
              }).format(estimatedTotalPrice)}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              disabled
            />
          </FormControl>
        </Grid>
        {conditionToDisplayMessage && <p className="info-blurb">Select a program from Program Name to display cart items.</p>}
        {cartItemsToDisplay.length > 0 && (
          <Grid
            item
            xs={15.5}
          >
            <div style={{ minHeight: 200, marginTop: "1rem" }}>
              <CartItemsGrid
                cartItemsToDisplay={cartItemsToDisplay}
                handleDeleteItemFromCart={handleDeleteItemFromCart}
                handleDeleteAllCartItems={handleDeleteAllCartItems}
                handleCartItemsDisplayed={handleCartItemsDisplayed}
              />
            </div>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
                marginBottom: "15px",
              }}
            >
              <Button
                onClick={handleFormattingForSubmit}
                variant="contained"
                disabled={cartItemsToDisplay.find((item) => {
                  if (!item.quantity) {
                    return true
                  } else {
                    return false
                  }
                })}
                sx={{
                  color: "white",
                  fontWeight: "bold",
                  width: "200px",
                  backgroundColor: "#105075",
                  border: "2px solid #646464",
                  marginRight: "1rem",
                }}
              >
                Submit
              </Button>
              <Tooltip
                title={
                  <Typography
                    fontSize={16}
                    fontStyle="italic"
                    textAlign="left"
                  >
                    <b>IMPORTANT:</b> This will remove <u>all</u> items from your cart for the current program listed. Only click if you wish to
                    proceed with removing all of the listed cart items above.
                  </Typography>
                }
                arrow
                followCursor
              >
                <Button
                  onClick={() => handleDeleteAllCartItems(programId)}
                  variant="contained"
                  disabled={!cartItemsToDisplay.length}
                  sx={{
                    marginLeft: "1rem",
                    color: "darkred",
                    fontWeight: "bold",
                    width: "200px",
                    backgroundColor: "#c2630aa1",
                    border: "2px solid darkred",
                    ":hover": {
                      backgroundColor: "#913138",
                      color: "white",
                    },
                  }}
                >
                  Remove All Items
                </Button>
              </Tooltip>
            </Box>
          </Grid>
        )}
      </Grid>
    </div>
  )
}

export default DC001CustomForm
