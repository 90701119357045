// ** Libraries
import { Route, Routes } from "react-router-dom"

// ** Custom
import DashboardView from "./views/DashboardView"
import InventoryRecordsTableView from "./views/InventoryRecordsTableView"
import BaseLayout from "./layout/BaseLayout"
import WarehousesView from "./views/WarehousesView"
import ArmyProgramsView from "./views/ArmyProgramsView"
import CustomForm from "./components/CustomForm"
import ImportForm from "./components/ImportForm"
import EditRequestForm from "./components/EditRequestForm"
import ListView from "./views/ListView"
import HistoricChangesView from "./views/HistoricChangesView"
import RequestSummaryView from "./views/RequestSummaryView"
import TransferSummaryView from "./views/TransferSummaryView"
import PendingInventoryChangesView from "./views/PendingInventoryChangesView"
import UsersView from "./views/UsersView"
import EditTransferRequestForm from "./components/EditTransferRequestForm"
import BrowserPrintComponent from "./components/BrowserPrinter/printerComponent"
import BrowserPrinterView from "./views/BrowserPrinterView"
import IssuedInventoryView from "./views/IssuedInventoryView"
import FilterReportsSelect from "./components/FilterReportsSelect"
import COSISView from "./views/COSISRelatedView"

const AppRoutes = ({
  handleUpdateUserPrograms,
  handleUpdateIsInvLoading,
  isInvLoading,
  userProgramsList,
  handleDeleteAllCartItems,
  allWRTData,
  handleSelect,
  selectedGlobalProgramId,
  handleAddItemsToCart,
  cartItems,
  handleDeleteItemFromCart,
  handleSubmitForm,
  handleEditFormSubmit,
  pendingChangesUpdate,
  handlePendingChangesUpdate,
  inventoryUpdate,
  handleInventoryUpdate,
  pendingChangesCount,
  handlePendingChangesCount,
  usersUpdate,
  handleUsersUpdate,
  handleSkipHazmat,
  handleReopenOrCancelWRT,
  leftMenuDrawerOpen,
  handleLeftMenuDrawer,
  handleDeleteItemEditForm,
  transferItems,
  handleTransferItems,
  handleTransferRequestSubmit,
  handleTransferRequestComplete,
  isAlertModalOpen,
  handleAlertModal,
}) => {
  return (
    <BaseLayout
      handleSelect={handleSelect}
      cartItems={cartItems}
      pendingChangesCount={pendingChangesCount}
      handleLeftMenuDrawer={handleLeftMenuDrawer}
      transferItems={transferItems}
      selectedGlobalProgramId={selectedGlobalProgramId}
      handleUpdateIsInvLoading={handleUpdateIsInvLoading}
      isInvLoading={isInvLoading}
    >
      <Routes>
        <Route
          exact
          path="/"
          element={
            <DashboardView
              selectedGlobalProgramId={selectedGlobalProgramId}
              allWRTData={allWRTData}
              handleEditFormSubmit={handleEditFormSubmit}
              handleSkipHazmat={handleSkipHazmat}
              handleDeleteItemEditForm={handleDeleteItemEditForm}
              handleReopenOrCancelWRT={handleReopenOrCancelWRT}
              isAlertModalOpen={isAlertModalOpen}
              handleAlertModal={handleAlertModal}
            />
          }
        />
        {/* Main */}
        <Route
          path="/dashboard"
          element={
            <DashboardView
              selectedGlobalProgramId={selectedGlobalProgramId}
              allWRTData={allWRTData}
              handleEditFormSubmit={handleEditFormSubmit}
              handleSkipHazmat={handleSkipHazmat}
              handleReopenOrCancelWRT={handleReopenOrCancelWRT}
              leftMenuDrawerOpen={leftMenuDrawerOpen}
              handleDeleteItemEditForm={handleDeleteItemEditForm}
              isAlertModalOpen={isAlertModalOpen}
              handleAlertModal={handleAlertModal}
            />
          }
        />
        <Route
          path="/inventory_records"
          element={
            <InventoryRecordsTableView
              pendingChangesCount={pendingChangesCount}
              selectedGlobalProgramId={selectedGlobalProgramId}
              handleAddItemsToCart={handleAddItemsToCart}
              handlePendingChangesCount={handlePendingChangesCount}
              leftMenuDrawerOpen={leftMenuDrawerOpen}
              handleTransferItems={handleTransferItems}
              pendingChangesUpdate={pendingChangesUpdate}
              handlePendingChangesUpdate={handlePendingChangesUpdate}
              inventoryUpdate={inventoryUpdate}
              handleInventoryUpdate={handleInventoryUpdate}
              handleUpdateIsInvLoading={handleUpdateIsInvLoading}
              isInvLoading={isInvLoading}
            />
          }
        />
        <Route
          path="/inventory_records/import"
          element={
            <ImportForm
              handleSelect={handleSelect}
              selectedGlobalProgramId={selectedGlobalProgramId}
            />
          }
        />
        <Route
          path="/inventory_records/historic_changes/:id"
          element={<HistoricChangesView leftMenuDrawerOpen={leftMenuDrawerOpen} />}
        />
        <Route
          path="/inventory_records/print/:id"
          element={<BrowserPrinterView leftMenuDrawerOpen={leftMenuDrawerOpen} />}
        />
        <Route
          path="/warehouses"
          element={<WarehousesView leftMenuDrawerOpen={leftMenuDrawerOpen} />}
        />
        <Route
          path="/army_programs"
          element={
            <ArmyProgramsView
              handleUpdateUserPrograms={handleUpdateUserPrograms}
              userProgramsList={userProgramsList}
              leftMenuDrawerOpen={leftMenuDrawerOpen}
            />
          }
        />
        <Route
          path="/issued"
          element={
            <IssuedInventoryView
              selectedGlobalProgramId={selectedGlobalProgramId}
              leftMenuDrawerOpen={leftMenuDrawerOpen}
            />
          }
        />
        <Route
          path="/COSIS"
          element={
            <COSISView
              selectedGlobalProgramId={selectedGlobalProgramId}
              userProgramsList={userProgramsList}
              leftMenuDrawerOpen={leftMenuDrawerOpen}
              handleUpdateIsInvLoading={handleUpdateIsInvLoading}
            />
          }
        />
        <Route
          path="/request_summary"
          element={
            <RequestSummaryView
              handleDeleteAllCartItems={handleDeleteAllCartItems}
              cartItems={cartItems}
              handleDeleteItemFromCart={handleDeleteItemFromCart}
              handleSubmitForm={handleSubmitForm}
            />
          }
        />
        <Route
          path="/transfer_summary"
          element={
            <TransferSummaryView
              transferItems={transferItems}
              handleTransferItems={handleTransferItems}
              handleTransferRequestSubmit={handleTransferRequestSubmit}
            />
          }
        />
        <Route
          path="/warehouse_request/:status_request/:id"
          element={
            <EditRequestForm
              handleEditFormSubmit={handleEditFormSubmit}
              leftMenuDrawerOpen={leftMenuDrawerOpen}
              handleDeleteItemEditForm={handleDeleteItemEditForm}
            />
          }
        />
        <Route
          path="/transfer_request_edit/:id"
          element={
            <EditTransferRequestForm
              leftMenuDrawerOpen={leftMenuDrawerOpen}
              handleTransferRequestComplete={handleTransferRequestComplete}
            />
          }
        />
        <Route
          path="/pending_changes"
          element={
            <PendingInventoryChangesView
              pendingChangesCount={pendingChangesCount}
              handlePendingChangesCount={handlePendingChangesCount}
              leftMenuDrawerOpen={leftMenuDrawerOpen}
              pendingChangesUpdate={pendingChangesUpdate}
              handlePendingChangesUpdate={handlePendingChangesUpdate}
            />
          }
        />
        <Route
          path="/users"
          element={
            <UsersView
              usersUpdate={usersUpdate}
              handleUsersUpdate={handleUsersUpdate}
              leftMenuDrawerOpen={leftMenuDrawerOpen}
            />
          }
        />
        {/*List Views within Army Programs and Warehouses*/}
        <Route
          path="/list_view/:id"
          element={<ListView leftMenuDrawerOpen={leftMenuDrawerOpen} />}
        />
        {/* CRUD */}
        <Route
          path="/:entity/:operation"
          element={
            <CustomForm
              leftMenuDrawerOpen={leftMenuDrawerOpen}
              handleUpdateUserPrograms={handleUpdateUserPrograms}
            />
          }
        />
        <Route
          path="/:entity/:operation/:id"
          element={
            <CustomForm
              leftMenuDrawerOpen={leftMenuDrawerOpen}
              selectedGlobalProgramId={selectedGlobalProgramId}
              handlePendingChangesCount={handlePendingChangesCount}
              handleUpdateUserPrograms={handleUpdateUserPrograms}
            />
          }
        />
      </Routes>
    </BaseLayout>
  )
}

export default AppRoutes
