import { Link } from "react-router-dom"
import { Fragment, useContext, useState } from "react"

// ** Context
import AppContext from "./AppContext"

// ** MUI
import { Badge, Collapse, List, ListItemIcon, ListItemButton, ListItemText } from "@mui/material"
import DashboardIcon from "@mui/icons-material/Dashboard"
import InventoryIcon from "@mui/icons-material/Inventory"
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech"
import WarehouseIcon from "@mui/icons-material/Warehouse"
import PendingActionsIcon from "@mui/icons-material/PendingActions"
import GroupIcon from "@mui/icons-material/Group"
import FactCheckIcon from "@mui/icons-material/FactCheck"
import SummarizeIcon from "@mui/icons-material/Summarize"
import StopIcon from "@mui/icons-material/Stop"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"

// ** Styles
import { themeVariables } from "./styleVariables"

const drawerMenuItems = [
  {
    name: "dashboard",
    title: "Dashboard",
    path: "/dashboard",
    icon: <DashboardIcon />,
    key: 1,
    adminItem: false,
    hasBadge: false,
    TLitem: true,
  },
  {
    name: "inventory_list_header",
    title: "Inventory",
    icon: <InventoryIcon />,
    key: 2,
    adminItem: false,
    hasBadge: false,
    TLitem: true,
    nestedList: [
      {
        name: "inventory_records",
        title: "Items",
        path: "/inventory_records",
        icon: <InventoryIcon />,
        key: 3,
        adminItem: false,
        hasBadge: false,
        TLitem: true,
      },
      {
        name: "COSIS",
        title: "COSIS",
        path: "/COSIS",
        icon: <SummarizeIcon />,
        key: 4,
        adminItem: false,
        TLitem: true,
        hasBadge: false,
      },
      {
        name: "issued",
        title: "Issued Items",
        path: "/issued",
        icon: <FactCheckIcon />,
        key: 5,
        adminItem: false,
        TLitem: true,
        hasBadge: false,
      },
    ],
  },
  {
    name: "pending_changes",
    title: "Pending Changes",
    path: "/pending_changes",
    icon: <PendingActionsIcon />,
    key: 6,
    adminItem: true,
    TLitem: true,
    hasBadge: true,
  },
  {
    name: "army_programs",
    title: "Programs",
    path: "/army_programs",
    icon: <MilitaryTechIcon />,
    key: 7,
    adminItem: false,
    hasBadge: false,
    TLitem: true,
  },
  {
    name: "warehouses",
    title: "Warehouses",
    path: "/warehouses",
    icon: <WarehouseIcon />,
    key: 8,
    adminItem: false,
    hasBadge: false,
    TLitem: true,
  },
  {
    name: "users",
    title: "Users",
    path: "/users",
    icon: <GroupIcon />,
    key: 9,
    adminItem: true,
    hasBadge: false,
    TLitem: false,
  },
]

const MenuItems = ({ selected, pendingChangesCount }) => {
  let context = useContext(AppContext)

  /*
   * NOTE: If there is more than one collapsible list in the future, we can establish an initial state
   * for each menu item in an object rather than having a state variable for each.
   */
  let initialState = Object.entries(drawerMenuItems.map((item) => [item.name, false]))
  const [open, setOpen] = useState(initialState)

  const handleClick = (itemName) => {
    setOpen((f) => ({ ...initialState, [itemName]: !f[itemName] }))
  }

  let filteredMenuItems = drawerMenuItems.filter((item) => {
    return !context.isAdmin && context.isTL ? item.TLitem : !context.isAdmin && !context.isTL ? !item.adminItem : context.isAdmin ? item : false
  })

  return (
    <List
      sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      component="nav"
    >
      {filteredMenuItems.map((item) => {
        if (item.nestedList) {
          return (
            <Fragment key={item.key}>
              <ListItemButton
                id={item.name}
                onClick={() => handleClick(item.name)}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.title} />
                {open[item.name] ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse
                in={open[item.name]}
                timeout="auto"
                unmountOnExit
              >
                <List
                  component="div"
                  disablePadding
                >
                  {item.nestedList.map((subItem) => {
                    return (
                      <Fragment key={subItem.key}>
                        <Link
                          to={subItem.path}
                          state={{ pathname: subItem.name, title: subItem.title, path: subItem.path }}
                        >
                          <ListItemButton
                            sx={{
                              pl: 4,
                              color: selected === subItem.name && `${themeVariables.app.palette.green}!important`,
                            }}
                            selected={selected === subItem.name}
                          >
                            <ListItemIcon>
                              <StopIcon sx={{ transform: "rotate(45deg)", height: "15px", width: "15px" }} />
                            </ListItemIcon>
                            <ListItemText primary={subItem.title} />
                          </ListItemButton>
                        </Link>
                      </Fragment>
                    )
                  })}
                </List>
              </Collapse>
            </Fragment>
          )
        } else {
          return (
            <Fragment key={item.key}>
              <Link
                to={item.path}
                state={{ pathname: item.name, title: item.title, path: item.path }}
              >
                <ListItemButton
                  sx={{
                    color: selected === item.name && `${themeVariables.app.palette.green}!important`,
                  }}
                  selected={selected === item.name}
                >
                  <ListItemIcon>
                    {item.hasBadge && item.name === "pending_changes" ? (
                      <Badge
                        color="error"
                        badgeContent={pendingChangesCount}
                        max={999}
                      >
                        {item.icon}
                      </Badge>
                    ) : (
                      <div>{item.icon}</div>
                    )}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </Link>
            </Fragment>
          )
        }
      })}
    </List>
  )
}

export default MenuItems
