import { useContext, useEffect, useRef, useState } from "react"

// *** MUI
import { Button, Grid } from "@mui/material"
import { styled } from "@mui/material/styles"
import { DataGridPremium, GridActionsCellItem, GridToolbarContainer } from "@mui/x-data-grid-premium"
import AddIcon from "@mui/icons-material/Add"
import DeleteIcon from "@mui/icons-material/Delete"
import FileDownloadIcon from "@mui/icons-material/FileDownload"
import VisibilityIcon from "@mui/icons-material/Visibility"

// ** API Calls
import apiCalls from "../apiCalls"
import { notify } from "../utils"

// ** Context
import AppContext from "../AppContext"

// ** Custom
import { InventoryChangeReasonModal } from "./InventoryChangeReasonModal"

const StyledDataGrid = styled("div")(() => ({
  height: 400,
  width: "100%",
  "& .MuiDataGrid-cell--editing": {
    backgroundColor: "rgb(255,215,115, 0.19)",
    color: "#1a3e72",
    "& .MuiInputBase-root": {
      height: "100%",
    },
  },
}))

const DocumentsInputForm = ({
  operation,
  type,
  programName,
  recordId,
  finalDocumentRows,
  handleFinalDocumentRows,
  handleFileConversion,
  isLoading,
}) => {
  let context = useContext(AppContext)
  const [rows, setRows] = useState(finalDocumentRows)
  const inputFile = useRef(null)
  const [toggleDeleteFileModal, setToggleDeleteFileModal] = useState(false)
  const [rowToDelete, setRowToDelete] = useState({})

  useEffect(() => {
    handleFinalDocumentRows(rows)
  }, [rows])

  useEffect(() => {
    if (operation === "edit") {
      // Fetch the existing files for a record when editing.
      apiCalls
        .getById(recordId, type === "inventoryRecord" ? "AttachmentRecords" : "BaseAttachmentRecords", "")
        .then((result) => {
          let files = result.data.map((file) => {
            const id = Math.floor(Math.random() * 100000)
            return {
              ...file,
              id: id,
              documentId: file.documentId,
              fileName: file.name,
              filePath: file.filePath,
            }
          })
          setRows(files)
        })
        .catch((error) => {
          console.error(("Error:", error))
          notify("error", `Item's files could not be retrieved.`)
        })
    }
  }, [])

  const DocumentsToolbar = () => {
    return (
      <GridToolbarContainer>
        <Button
          startIcon={<AddIcon />}
          onClick={handleClick}
          disabled={context.isInventoryManager}
        >
          <input
            type="file"
            ref={inputFile}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />
          Add
        </Button>
      </GridToolbarContainer>
    )
  }

  const handleClick = (e) => {
    inputFile.current.click()
  }

  const handleFileChange = (e) => {
    const fileUploaded = e.target.files[0]
    handleAddClick(fileUploaded)
  }

  const handleAddClick = (selectedFile) => {
    const id = Math.floor(Math.random() * 100000)

    let newRow = {
      id: id,
      fileName: selectedFile.name,
      fileObject: selectedFile,
      filePath:
        type === "inventoryRecord"
          ? `${programName}/Inventory Documents/${selectedFile.name}`
          : `${programName}/Warehouse Request Documents/${selectedFile.name}`, // Add file path here in case user wants to view document without backing out of the page.
      isNew: true,
    }

    // If editing a record, immediately add any new file to MinIO.
    if (operation === "edit" && newRow.hasOwnProperty("isNew")) handleNewFileSubmission(newRow)

    setRows((oldRows) => [...oldRows, newRow])
  }

  // Used only when adding a new file when editing.
  const handleNewFileSubmission = async (file) => {
    await new Promise(handleFileConversion(file.fileObject)).then(async (result) => {
      let basicRequest = {
        bucketName: "awims",
        objectName: file.fileName,
        filePath: type === "inventoryRecord" ? `${programName}/Inventory Documents` : `${programName}/Warehouse Request Documents`,
        file: result,
      }

      try {
        let response = await apiCalls.putFile(basicRequest)
        if (response.status === 200) {
          let newDocumentId = response.data.result.value.id
          let body = {
            inventoryRecordId: recordId,
            documentId: newDocumentId,
          }

          // Create the relationship between the record and the document.
          await apiCalls.postRecord(body, type === "inventoryRecord" ? "AttachmentRecords" : "BaseAttachmentRecords")
          notify("success", `\"${file.fileName}\" was successfully uploaded.`)
        }
      } catch (error) {
        console.error("Error:", error)
        notify("error", `${file.fileName} could not be uploaded. Please try again.`)
      }
    })
  }

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id))
  }

  const handleConfirmDeleteFile = async (response) => {
    if (response) {
      let response = await apiCalls.deleteAttachmentRecordByIds(recordId, rowToDelete.documentId, type)
      if (response.status === 204) {
        notify("success", `\"${rowToDelete.fileName}\" was removed successfully.`)
        setRows(rows.filter((row) => row.id !== rowToDelete.id))
        setRowToDelete({})
      } else notify("error", `${rowToDelete.fileName} could not be removed. Please try again.`)
    }
    setToggleDeleteFileModal(false)
  }

  // File actions, such as downloading and deleting an existing file.
  const handleFileAction = async (id, action) => {
    let getRow = rows.find((file) => file.id === id)

    /*
     * File path will differ between documents attached at receiving and those added later once an item
     * has moved to a new program.
     */
    let splitFilePath = getRow.filePath.split("/")

    if (action === "delete") {
      setRowToDelete(getRow)
      setToggleDeleteFileModal(true)
    } else {
      try {
        await apiCalls
          .downloadFile(
            "awims",
            splitFilePath[0],
            type === "inventoryRecord" ? "Inventory Documents" : "Warehouse Request Documents",
            getRow.fileName
          )
          .then((response) => {
            const file = new Blob([response.data], { type: "application/pdf" })
            const url = window.URL.createObjectURL(file)

            if (action === "view") {
              // Open the document in a new tab.
              window.open(url, "_blank")
            } else {
              // Create a temporary <a> element to trigger download.
              const tempLink = document.createElement("a")
              tempLink.href = url

              // Set filename for the downloaded file.
              tempLink.setAttribute("download", getRow.fileName)

              // Append the <a> element to the body and click to trigger download.
              document.body.appendChild(tempLink)
              tempLink.click()

              // Clean up the temporary elements and URL.
              document.body.removeChild(tempLink)
            }

            window.URL.revokeObjectURL(url)
          })
      } catch (error) {
        console.log("Error:", error)
        notify("error", `File action could not be completed. Please try again.`)
      }
    }
  }

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false }
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
    return updatedRow
  }

  const columns = [
    { field: "fileName", headerName: "File Name", minWidth: operation === "multipleRecordUpload" ? 400 : 680, flex: 1 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      minWidth: 150,
      getActions: ({ id }) => {
        if (operation === "edit") {
          return [
            <GridActionsCellItem
              icon={<VisibilityIcon />}
              label="View"
              onClick={() => handleFileAction(id, "view")}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<FileDownloadIcon />}
              label="Download"
              onClick={() => handleFileAction(id, "download")}
              color="primary"
            />,
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleFileAction(id, "delete")}
              color="error"
              disabled={context.isInventoryManager}
            />,
          ]
        } else {
          return [
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="error"
            />,
          ]
        }
      },
    },
  ]

  return (
    <>
      {toggleDeleteFileModal && (
        <InventoryChangeReasonModal
          shouldOpen={true}
          handleConfirmClose={handleConfirmDeleteFile}
          title="Confirm Delete"
          type="deleteFile"
          text={rowToDelete.fileName}
        />
      )}
      <Grid>
        <StyledDataGrid>
          <DataGridPremium
            columns={columns}
            rows={rows}
            // Loading only configured for the DocumentAttachmentModal component table.
            loading={operation === "multipleRecordUpload" && isLoading}
            disableRowSelectionOnClick
            getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "even-row" : "odd-row")}
            processRowUpdate={processRowUpdate}
            slots={{ toolbar: DocumentsToolbar }}
            sx={{
              boxShadow: 3,
              border: "1px solid",
              borderColor: "rgba(16, 80, 117, 0.5)",
              "& .even-row.MuiDataGrid-row": {
                backgroundColor: "rgba(219, 231, 229, 0.35)",
                "&:hover, &.Mui-hovered": {
                  backgroundColor: "rgba(84, 187, 253, 0.1)",
                  "@media (hover: none)": {
                    backgroundColor: "transparent",
                  },
                },
              },
              "& .MuiDataGrid-toolbarContainer": {
                background: "rgba(219, 231, 229, 0.35)",
                border: "1px solid",
                borderColor: "rgba(16, 80, 117, 0.5)",
              },
            }}
          />
        </StyledDataGrid>
      </Grid>
    </>
  )
}

export default DocumentsInputForm
