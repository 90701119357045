import { useContext, useEffect, useState } from "react"
import { useLocation, useNavigate, useParams } from "react-router-dom"

// ** Toastify
import { ToastContainer } from "react-toastify"

// * MUI
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Popover,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"
import UploadFileIcon from "@mui/icons-material/UploadFile"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Styles
import "../components/styles/CustomFormStyles.css"

// ** Custom
import LoadingBackdrop, {
  getBase64,
  getFormattedDate,
  getProgramName,
  notify,
  permissionCheck,
  isRequestLocal,
  getTimezoneFormattedDate,
} from "../utils"
import Header from "../layout/Header"
import DocumentsInputForm from "./DocumentsInputForm"
import fillPdfForm from "../pdfFormService"
import RequestItemsGrid from "./RequestItemsGrid"
import SignaturesTable from "./SignaturesTable"

const EditRequestForm = ({ handleEditFormSubmit, leftMenuDrawerOpen, handleDeleteItemEditForm }) => {
  let context = useContext(AppContext)
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  const { id, status } = params
  const [loaded, setLoaded] = useState(false)
  const [requestForm, setRequestForm] = useState("")
  const [errors, setErrors] = useState("")
  const [base64Form, setBase64Form] = useState("")
  const [anchorPopover, setAnchorPopover] = useState()
  const openPopover = Boolean(anchorPopover)
  const notPermitted = permissionCheck(["Government Customer", "Program Customer", "Inventory Manager"], context.usersRoles)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false)
  const [isLocal, setIsLocal] = useState(false)
  const [refRequestItems, setRefRequestItems] = useState("")

  let pathname = window.location.pathname.split("/")[2]
  let isPending = location.state.isPending
  let isComplete = location.state.reqStatus === "complete"
  let signaturesList = location.state.signaturesList

  // For document entry:
  const [finalDocumentRows, setFinalDocumentRows] = useState([])

  useEffect(() => {
    fetchWRT()
  }, [])

  useEffect(() => {
    if (requestForm) {
      setIsLocal(isRequestLocal(requestForm.requestType))
      setLoaded(true)
    }
  }, [requestForm])

  useEffect(() => {
    if (isPending && requestForm.requestItems) {
      let estTotalPrice = [...requestForm.requestItems].reduce((acc, item) => {
        acc += item.totalCost
        return acc
      }, 0)

      setRequestForm((prevForm) => {
        return {
          ...prevForm,
          estimatedTotalPrice: estTotalPrice,
        }
      })
    }
  }, [requestForm.requestItems])

  const fetchWRT = async () => {
    try {
      let WRTobj = await apiCalls.getById(id, "WarehouseRequestTransactions", "?%24expand=requestItems")
      let baseWRT = WRTobj.data

      /*
       * NOTE: If a WRT is being reopened or has been cancelled, either grab the issued records from the IssuedRecords
       * database table, or if returns an empty array, use the existing logged request items.
       */
      if (baseWRT.requestItems.length === 0) {
        let reqItemsRef
        let issuedRecords = await apiCalls.getRecords(`IssuedRecords?%24filter=warehouseRequestTransactionId%20eq%20${baseWRT.id}`)
        if (issuedRecords.data.value.length === 0) {
          let log = await apiCalls.getCollectionByTransactionId(id, "WarehouseRequestTransaction", "RequestItems")
          reqItemsRef = log.data.map((el) => {
            return el.warehouseRequestItem
          })
        } else reqItemsRef = issuedRecords.data.value

        // NOTE: Do NOT set requestItems in below body to reqItemsRef. This ends up causing a database concurrency issue.
        let viewOnlyRequest = {
          ...baseWRT,
          signatureList: signaturesList,
        }

        setRequestForm(viewOnlyRequest)
        setRefRequestItems(reqItemsRef)
      } else setRequestForm(baseWRT)
    } catch (error) {
      console.error(error)
      notify("error", `There was a problem loading your request. Please try again.`)
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setRequestForm((prevForm) => {
      return {
        ...prevForm,
        [name]: value,
      }
    })
  }

  /// *** PDF-RELATED - START *** ///
  const handlePdfForm = async () => {
    const groupedItems = groupRequestItems(requestForm.requestItems.length === 0 ? refRequestItems : requestForm.requestItems)
    const base64Form = await fillPdfForm(requestForm, groupedItems)
    setBase64Form(base64Form)
  }

  // Group the requested items by NSN and P/N to track all serialized items of the same type together.
  const groupRequestItems = (items) => {
    let groupedByNsnPn = {}

    items.forEach((item) => {
      let namingConvention = item.nationalStockNumber + ":" + item.partNumber
      // Differentiate the bulk items from each other in the event there's two (or more) of the same item. If not, they get grouped as a serialized item.
      const groupName = item.serialNumber === "" || item.serialNumber === null ? namingConvention + ":" + item.propertyNumber : namingConvention
      groupedByNsnPn[groupName] = groupedByNsnPn[groupName] || []
      groupedByNsnPn[groupName].push(item)
    })

    const resultArray = Object.values(groupedByNsnPn)
    return resultArray
  }
  /// *** PDF-RELATED - END *** ///

  const handleUpdateRequestItems = (updatedRequestItems) => {
    setRequestForm((prevForm) => {
      return {
        ...prevForm,
        requestItems: updatedRequestItems,
      }
    })
  }

  const handleEditForm = async () => {
    await handleEditFormSubmit(id, requestForm, pathname)
  }

  const handleDisableSubmitButton = (boolean) => {
    setIsSubmitDisabled(boolean)
  }

  const handleFinalDocumentRows = (rows) => {
    setFinalDocumentRows(rows)
  }

  const handlePopoverOpen = (e) => {
    setAnchorPopover(e.currentTarget)
  }

  const handlePopoverClose = (e) => {
    setAnchorPopover(null)
  }

  let requestItemsTitle = requestForm.status === "COMPLETE" ? "Confirmed" : requestForm.status === "CANCELLED" ? "Cancelled" : ""
  let isPullRequest = requestForm.requestType === "Pull Request - Assets requested to be shipped" && isPending
  let isCancelled = !isPending && requestForm.status === "CANCELLED" ? true : false
  let toolTipTitle = isSubmitDisabled ? "* Click out of the cell above in order to submit your change(s)." : "Submit"
  if (loaded) {
    return (
      <Container className="form-bg">
        <div className="back-btn-wrapper">
          <IconButton
            onClick={() => navigate(-1)}
            size="large"
          >
            <ArrowBackIcon fontSize="inherit" />
          </IconButton>
        </div>
        <div className="header-padding">
          <Header title={`${requestForm.documentNumber}: ${requestForm.status}`} />
        </div>
        <div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
        <Grid
          container
          spacing={2}
          columns={11}
        >
          <Grid
            item
            xs={5.5}
          >
            <Grid
              container
              spacing={1.35}
              columns={5}
              sx={{ paddingLeft: 1.35 }}
            >
              <Grid
                item
                xs={4.5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="documentNumber"
                    id="document-number"
                    label="Document Number"
                    type="text"
                    value={requestForm.documentNumber}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={4.5}
              >
                <Divider sx={{ color: "grey", fontFamily: "Merriweather" }}>Request Information</Divider>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="requestedBy"
                    id="requested-by"
                    label="Requested By"
                    type="text"
                    value={requestForm.requestedBy}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <InputLabel
                    disabled
                    shrink
                  >
                    Request Date
                  </InputLabel>
                  <OutlinedInput
                    name="requestDate"
                    id="request-date"
                    size="small"
                    margin="dense"
                    notched
                    label="Request Date"
                    type="text"
                    disabled
                    value={getTimezoneFormattedDate(requestForm.requestDate, true)}
                  />
                </FormControl>
              </Grid>

              <Grid
                item
                xs={4.5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="requestType"
                    id="request-type"
                    label="Request Type"
                    type="text"
                    value={requestForm.requestType}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="programName"
                    id="program-name"
                    label="Program Name"
                    type="text"
                    value={requestForm.programName}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <InputLabel
                    disabled
                    shrink
                  >
                    Required Delivery Date
                  </InputLabel>
                  <OutlinedInput
                    name="requiredDeliveryDate"
                    id="required-delivery-date"
                    size="small"
                    margin="dense"
                    notched
                    label="Required Delivery Date"
                    type="text"
                    disabled
                    value={getFormattedDate(requestForm.requiredDeliveryDate)}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={4.5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="priority"
                    id="priority"
                    label="Priority"
                    type="text"
                    value={requestForm.priority}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="specialInstructions"
                    id="special-instructions"
                    label="Special Instructions"
                    type="text"
                    value={requestForm.specialInstructions}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="estimatedTotalPrice"
                    id="estimated_total_price"
                    label="Estimated Total Price"
                    type="text"
                    value={Intl.NumberFormat("en-US", {
                      maximumFractionsDigits: 2,
                      minimumFractionDigits: 2,
                    }).format(requestForm.estimatedTotalPrice)}
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* end of first Grid container as item */}
          {/* start of second Grid container as item */}
          <Grid
            item
            xs={5.5}
            sx={{ display: "flex" }}
          >
            <Grid
              container
              spacing={1.45}
              columns={5}
              alignContent="space-between"
            >
              <Grid
                item
                xs={5}
                sx={{ paddingTop: "0px !important" }}
              >
                <Divider sx={{ color: "grey", fontFamily: "Merriweather" }}>POC Information</Divider>
              </Grid>
              <Grid
                item
                xs={5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="pocName"
                    id="poc-name"
                    label="POC Name"
                    type="text"
                    value={requestForm.pocName}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="pocEmail"
                    id="poc-email"
                    label="POC Email"
                    type="text"
                    value={requestForm.pocEmail}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="pocPhone"
                    id="poc-phone"
                    label="POC Phone"
                    type="text"
                    value={requestForm.pocPhone}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={5}
              >
                <Divider sx={{ color: "grey", fontFamily: "Merriweather" }}>Shipping Information</Divider>
              </Grid>
              <Grid
                item
                xs={5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    id="dodAac_Uic"
                    label="DODAAC/UIC"
                    name="dodAac_Uic"
                    type="text"
                    value={requestForm.dodAac_Uic}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    id="address"
                    name="address"
                    label="Address"
                    type="text"
                    value={requestForm.address}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled={!isPending || notPermitted || !isPullRequest}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="activityName"
                    id="activityName"
                    label="Activity Name"
                    type="text"
                    value={requestForm.activityName}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl sx={{ minWidth: "100%" }}>
                  <TextField
                    name="city_State_Zip"
                    id="city_State_Zip"
                    label="City, State, Zip"
                    type="text"
                    value={requestForm.city_State_Zip}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    disabled={!isPending || notPermitted || !isPullRequest}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {/* ITEM AS WRAPPER : */}
          <Grid
            item
            xs={11}
          >
            {/* CONTAINER AS ITEM : */}
            <Grid
              container
              spacing={1.2}
              columns={10}
            >
              {/* ITEM WITHIN CONTAINER : */}
              <Grid
                item
                xs={10}
                sx={{ paddingTop: "4px !important" }}
              >
                <Divider className={!isPending ? "divider-disabled" : "divider"}>Dimensions</Divider>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl
                  sx={{
                    minWidth: "100%",
                  }}
                >
                  <TextField
                    required={isPullRequest}
                    id="length"
                    type="text"
                    size="small"
                    name="dimensionsLength"
                    label="Length"
                    value={requestForm.dimensionsLength}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    error={errors.dimensionsLength}
                    helperText={errors.dimensionsLength && "* Required"}
                    disabled={!isPending || notPermitted}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl
                  sx={{
                    minWidth: "100%",
                  }}
                >
                  <TextField
                    required={isPullRequest}
                    id="width"
                    type="text"
                    name="dimensionsWidth"
                    label="Width"
                    value={requestForm.dimensionsWidth}
                    onChange={handleChange}
                    size="small"
                    error={errors.dimensionsWidth}
                    helperText={errors.dimensionsWidth && "* Required"}
                    InputLabelProps={{ shrink: true }}
                    disabled={!isPending || notPermitted}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl
                  sx={{
                    minWidth: "100%",
                  }}
                >
                  <TextField
                    required={isPullRequest}
                    id="height"
                    type="text"
                    size="small"
                    label="Height"
                    value={requestForm.dimensionsHeight}
                    onChange={handleChange}
                    error={errors.dimensionsHeight}
                    helperText={errors.dimensionsHeight && "* Required"}
                    InputLabelProps={{ shrink: true }}
                    name="dimensionsHeight"
                    disabled={!isPending || notPermitted}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={2.5}
              >
                <FormControl
                  sx={{
                    minWidth: "100%",
                  }}
                >
                  <TextField
                    required={isPullRequest}
                    name="weight"
                    id="weight"
                    label="Weight"
                    type="text"
                    value={requestForm.weight}
                    onChange={handleChange}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    error={errors.weight}
                    helperText={errors.weight && "* Required"}
                    disabled={!isPending || notPermitted}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={11}
          >
            <Grid
              container
              spacing={1.2}
              columns={10}
            >
              <Grid
                item
                xs={10}
                sx={{ paddingTop: "4px !important" }}
              >
                <Divider className={!isPending ? "divider-disabled" : "divider"}>Documents</Divider>
              </Grid>
              <Grid
                item
                sx={{ minWidth: "100%" }}
              >
                <Accordion sx={{ minWidth: "100%" }}>
                  <AccordionSummary
                    expandIcon={<ArrowDropDownIcon />}
                    aria-controls="panel1-content"
                    id="panel1-header"
                    sx={{ fontSize: "small", color: "gray" }}
                  >
                    {<UploadFileIcon fontSize="small" />} Upload Document(s)
                  </AccordionSummary>
                  <AccordionDetails>
                    <DocumentsInputForm
                      operation="edit"
                      type="warehouseRequest"
                      programName={getProgramName(requestForm.programId, context.allProgramsData)}
                      recordId={requestForm.id}
                      finalDocumentRows={finalDocumentRows}
                      handleFinalDocumentRows={handleFinalDocumentRows}
                      handleFileConversion={getBase64}
                      isLoading={null}
                    />
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>
          {/* // end of dimensions/documents, start of req items */}
          {!isPending && (
            <Grid
              item
              xs={11}
            >
              <Grid
                container
                spacing={1.2}
                columns={10}
              >
                <Grid
                  item
                  xs={10}
                  sx={{ paddingTop: "2px !important" }}
                >
                  <Divider className="divider-disabled">{`Additional ${isCancelled ? "Cancellation" : "Confirmation"} Info`}</Divider>
                </Grid>
                {!isLocal ? (
                  <Grid
                    item
                    xs={3.3333}
                  >
                    <FormControl
                      sx={{
                        minWidth: "100%",
                      }}
                    >
                      <TextField
                        name={isCancelled ? "cancelDate" : "carrierInformation"}
                        id={isCancelled ? "cancelDate" : "carrierInformation"}
                        label={isCancelled ? "Cancelled Date " : "Carrier Info"}
                        type="text"
                        value={isCancelled ? getTimezoneFormattedDate(requestForm.cancelDate, true) : requestForm.carrierInformation}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        disabled
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid />
                )}
                {!isLocal ? (
                  <Grid
                    item
                    xs={3.3333}
                  >
                    <FormControl
                      sx={{
                        minWidth: "100%",
                      }}
                    >
                      <TextField
                        name={isCancelled ? "cancelSignature" : "trackingNumber"}
                        id={isCancelled ? "cancelSignature" : "trackingNumber"}
                        label={isCancelled ? "Cancelled By " : "Tracking Number"}
                        type="text"
                        value={isCancelled ? requestForm.cancelSignature : requestForm.trackingNumber}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        disabled
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid />
                )}
                {!isCancelled && !isLocal ? (
                  <Grid
                    item
                    xs={3.3333}
                  >
                    <FormControl
                      sx={{
                        minWidth: "100%",
                      }}
                    >
                      <TextField
                        name={"transportationAccountCode"}
                        id={"transportationAccountCode"}
                        label={"Transportation Account Code"}
                        type="text"
                        value={requestForm.transportationAccountCode}
                        InputLabelProps={{ shrink: true }}
                        size="small"
                        disabled
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid />
                )}
                <Grid
                  item
                  xs={12}
                >
                  <FormControl
                    sx={{
                      minWidth: "100%",
                    }}
                  >
                    <TextField
                      name={isCancelled ? "cancelReason" : "notes"}
                      id={isCancelled ? "cancelReason" : "notes"}
                      label={isCancelled ? "Reason(s) For Cancellation" : "Notes"}
                      type="text"
                      value={isCancelled ? requestForm.cancelReason : requestForm.notes}
                      InputLabelProps={{ shrink: true }}
                      size="small"
                      multiline={true}
                      rows={3}
                      disabled
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid
            item
            xs={11}
          >
            <Grid
              container
              spacing={1.2}
              columns={10}
            >
              <Grid
                item
                xs={10}
                sx={{ paddingTop: "8px !important" }}
              >
                <Divider className={!isPending ? "divider-disabled" : "divider"}>{`${requestItemsTitle} Request Items`}</Divider>
              </Grid>
              <Grid
                item
                xs={10}
              >
                <RequestItemsGrid
                  requestForm={requestForm}
                  isSubmitDisabled={isSubmitDisabled}
                  handleDisableSubmitButton={handleDisableSubmitButton}
                  isPending={isPending}
                  requestItems={requestForm.requestItems.length === 0 ? refRequestItems : requestForm.requestItems}
                  handleUpdateRequestItems={handleUpdateRequestItems}
                  partsSignature={requestForm.partsSignature}
                  partsDate={requestForm.partsDate}
                  handleDeleteItemEditForm={handleDeleteItemEditForm}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* // end of request items section divider included */}
          {!isPending && (
            <Grid
              item
              xs={11}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid
                container
                spacing={1}
                columns={10}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid
                  item
                  xs={10}
                  sx={{ paddingTop: "3px !important" }}
                >
                  <Divider className="divider-disabled">{`Signatures`}</Divider>
                </Grid>
                <Grid
                  item
                  xs={7}
                >
                  <SignaturesTable {...requestForm} />
                </Grid>
              </Grid>
            </Grid>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "15px",
              marginBottom: "15px",
              width: "100%",
            }}
          >
            {isPending && !notPermitted && (
              <Tooltip
                title={
                  <Typography
                    fontSize={14}
                    fontStyle="italic"
                  >
                    {toolTipTitle}
                  </Typography>
                }
                arrow
                followCursor
              >
                <span>
                  <Button
                    variant="contained"
                    onClick={handleEditForm}
                    disabled={isSubmitDisabled || !isPending}
                    sx={{
                      color: "white",
                      fontWeight: "bold",
                      width: "160px",
                      backgroundColor: "#105075",
                      border: "2px solid #646464",
                    }}
                  >
                    Submit
                  </Button>
                </span>
              </Tooltip>
            )}
            {(isPending || isComplete) && (
              <>
                <Button
                  variant="contained"
                  onClick={handlePdfForm}
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  sx={{
                    color: "#105075",
                    fontWeight: "bold",
                    width: "160px",
                    marginLeft: "25px",
                  }}
                >
                  Generate DD1149
                </Button>
                {isPending && (
                  <Popover
                    id="mouse-over-popover"
                    sx={{ pointerEvents: "none" }}
                    open={openPopover}
                    anchorEl={anchorPopover}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "center",
                      horizontal: "left",
                    }}
                    slotProps={{
                      paper: {
                        sx: {
                          width: "300px",
                          height: "140px",
                          marginLeft: "15px",
                        },
                      },
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <Typography sx={{ p: 1, textAlign: "left" }}>
                      <em>
                        <b style={{ color: "red" }}>IMPORTANT:</b> If any changes are made to the requested items (i.e. serial number, quantity), the
                        changes must be submitted first before generating a new DD1149.
                      </em>
                    </Typography>
                  </Popover>
                )}
              </>
            )}
          </Box>
        </Grid>
        <hr />
        <iframe
          title="DD1149"
          hidden={!base64Form}
          src={base64Form}
          width="100%"
          height={700}
        />
      </Container>
    )
  } else {
    return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
  }
}

export default EditRequestForm
