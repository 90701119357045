// ** Libraries
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { useEffect, useState } from "react"
import { useAuth, hasAuthParams } from "react-oidc-context"
import axios from "axios"
import { LicenseInfo } from "@mui/x-license-pro"

// ** React Router
import { useNavigate } from "react-router-dom"

// ** Custom
import AppRoutes from "./AppRoutes"
import AppContext from "./AppContext"
import { context } from "./context/variables"
import { filterActiveData, filterPendingChanges, notify } from "./utils"

// ** SignalR
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr"

// ** Toastify
import "react-toastify/dist/ReactToastify.css"

// ** Styles
import "./App.css"
import "./index.css"
import { themeVariables } from "./styleVariables"
import apiCalls from "./apiCalls"
import CustomProgressDialog from "./components/CustomProgressDialog"
import FailedLoginDialog from "./components/FailedLoginDialog"

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE)

const appTheme = createTheme({
  typography: {
    fontFamily: "Roboto",
    fontWeight: "400",
    h1: {
      fontSize: "2rem",
      fontFamily: "Merriweather",
      fontWeight: "700",
    },
    h2: {
      fontSize: "1.5rem",
      fontWeight: "500",
    },
    h5: {
      fontSize: "1rem",
    },
    button: {
      fontSize: ".9rem",
    },
    key: {
      fontSize: "1rem",
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "#54bbfd",
      hover: "#54bbfd",
      white: "#ffffff",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          boxShadow: "none",
          borderBottom: `1px solid ${themeVariables.app.palette.border}`,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          color: themeVariables.app.palette.text,
          backgroundColor: "white",
          width: "100%",
        },
        main: {},
        toolbarContainer: {
          border: `2px solid ${themeVariables.app.palette.border}`,
          backgroundColor: themeVariables.app.palette.green,
          padding: "5px",
        },
        virtualScroller: {
          overflowX: "scroll",
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 10,
          justifyContent: "center",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          fontWeight: "400",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          // color: "inherit"
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          justifyContent: "center",
          alignItems: "center",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {},
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontFamily: "Merriweather",
          fontWeight: "400",
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {},
        item: {},
      },
    },
  },
})

const App = () => {
  const auth = useAuth()
  const [hasTriedSignin, setHasTriedSignin] = useState(false)
  const [isUserLoaded, setIsUserLoaded] = useState(false)
  const [selectedGlobalProgramId, setSelectedGlobalProgramId] = useState(JSON.parse(localStorage.getItem("selectedProgramId")))
  const [cartItems, setCartItems] = useState(JSON.parse(localStorage.getItem("cartItems")) || [])
  const navigate = useNavigate()
  const [allWRTData, setAllWRTData] = useState("")
  // QUESTION: is the below being used?
  const [allTransferTransactionData, setAllTransferTransactionData] = useState("")
  const [pendingChangesCount, setPendingChangesCount] = useState(0)
  const [inventoryUpdate, setInventoryUpdate] = useState(false)
  const [pendingChangesUpdate, setPendingChangesUpdate] = useState(false)
  const [usersUpdate, setUsersUpdate] = useState(false)
  const [leftMenuDrawerOpen, setLeftMenuDrawerOpen] = useState(true)
  const [transferItems, setTransferItems] = useState(JSON.parse(localStorage.getItem("transferItems")) || [])
  const [isAlertModalOpen, setIsAlertModalOpen] = useState(false)
  const [userProgramsList, setUserProgramsList] = useState("")
  const [loaded, setLoaded] = useState(false)
  const [isInvLoading, setIsInvLoading] = useState(false)

  // NOTE: PLEASE DO NOT REMOVE.
  // WebSocket Variable
  // const [webSocketConnection, setWebSocketConnection] = useState()
  // const [webSocketMessages, setWebSocketMessages] = useState([])
  // const [isConnectionAlive, setIsConnectionAlive] = useState(false)
  // const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
      auth.signinRedirect()
      setHasTriedSignin(true)
    }
    if (auth.isAuthenticated) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${auth.user.access_token}`
      checkAuthRoles()
    }
  }, [auth, hasTriedSignin])

  useEffect(() => {
    isUserLoaded && fetchAppData()
  }, [isUserLoaded])

  useEffect(() => {
    userProgramsList && setLoaded(true)
  }, [userProgramsList])

  const checkAuthRoles = async () => {
    const currentUser = auth.user.profile

    context.usersRoles = currentUser.roles
    context.userEmail = currentUser.email
    // context.isAdmin = false
    context.isAdmin = currentUser.roles.includes("Administrator")
    context.isTI = currentUser.roles.includes("Technical Inspector")
    context.isReceiving = currentUser.roles.includes("Receiving")
    context.isICP = currentUser.roles.includes("Inventory Control Point")
    context.isCustomer = currentUser.roles.includes("Government Customer") || currentUser.roles.includes("Program Customer") ? true : false
    context.isShipping = currentUser.roles.includes("Shipping")
    context.isHazmat = currentUser.roles.includes("Hazmat")
    context.isTL = currentUser.roles.includes("Team Lead")
    context.isQA = currentUser.roles.includes("Quality Assurance")
    context.isTurnIn = currentUser.roles.includes("Turn-In")
    context.isInventoryManager = currentUser.roles.includes("Inventory Manager")
    context.username = currentUser.preferred_username
    context.fullName = currentUser.given_name + " " + currentUser.family_name

    context.KCprogramIds = currentUser.program_ids

    // Check if user is a newly registered user.
    let alertInformation = {
      inRoles: auth.user.profile.roles.length > 3 ? true : false,
      inPrograms: auth.user.profile.hasOwnProperty("program_ids").length !== 0 ? true : false,
    }
    setIsAlertModalOpen(Object.values(alertInformation).includes(false) ? true : false)
    setIsUserLoaded(true)
  }

  const finalizeUserPrograms = async (allProgramsData, KCIds) => {
    context.userPrograms = await getUserProgramData(allProgramsData, KCIds)
    let allProgramsList = await organizePrograms(allProgramsData)
    context.finalProgramsList = allProgramsList

    let parentPrograms = allProgramsList.filter((program) => program.subPrograms || !program.parentProgramId)
    context.parentPrograms = parentPrograms

    return allProgramsList
  }

  const getUserProgramData = async (allProgramsData, KCIds) => {
    let ids = !KCIds ? [context.KCprogramIds] : [...KCIds]
    const userPrograms = ids
      .map((programId) => {
        const foundAP = allProgramsData.find((el) => el.id.toUpperCase() === programId)
        return {
          ...foundAP,
        }
      })
      .sort((a, b) => a.name?.replace(/[^\w\s]/gi, "").localeCompare(b.name?.replace(/[^\w\s]/gi, "")))
    return userPrograms
  }

  // Organizes programs by setting sub-programs to their parent programs.
  const organizePrograms = async (programsDataToMap) => {
    const freestyle = programsDataToMap.reduce((acc, currVal) => {
      const subP = programsDataToMap.filter((p) => p.parentProgramId === currVal.id)
      if (subP.length) currVal.subPrograms = subP
      acc.push(currVal)
      return acc
    }, [])
    return freestyle
  }

  const fetchAppData = async () => {
    let wrt = apiCalls.getRecords("WarehouseRequestTransactions")
    let ap = apiCalls.getRecords("ArmyPrograms")
    let wh = apiCalls.getRecords("Warehouses")
    let tt = apiCalls.getRecords("TransferTransactions")
    let pc = apiCalls.getRecords("PendingInventoryRecordChanges")
    let cr = apiCalls.getKeycloakRoles()
    let cg = apiCalls.getKeycloakPrograms()
    let dd = apiCalls.getRecords("Deficiencies")
    let resolved = await Promise.all([wrt, ap, wh, tt, pc, cr, cg, dd])

    context.allProgramsData = await filterActiveData(resolved[1].data.value.sort((a, b) => a.name.localeCompare(b.name)))
    context.warehouseData = await filterActiveData(resolved[2].data.value.sort((a, b) => a.buildingNumber.localeCompare(b.buildingNumber)))

    let allPendingChangesData = resolved[4].data

    setAllWRTData(resolved[0].data)
    setAllTransferTransactionData(resolved[3].data.value)

    context.clientRoleData = resolved[5].data
    context.clientGroupData = resolved[6].data
    context.deficiencyData = resolved[7].data.value

    let formattedWithSub = await finalizeUserPrograms(context.allProgramsData, context.KCprogramIds)

    setUserProgramsList(formattedWithSub)

    if (context.isAdmin) {
      setPendingChangesCount(allPendingChangesData.length)
    } else if (!context.isAdmin && context.isTL) {
      let filteredPending = await filterPendingChanges(allPendingChangesData, context.userPrograms)
      setPendingChangesCount(filteredPending.length)
    }

    // If no Program is selected in Dropdown:
    if (context.isAdmin && !selectedGlobalProgramId) {
      let firstProgram = context.allProgramsData[0].id
      setSelectedGlobalProgramId(firstProgram)
      localStorage.setItem("selectedProgramId", JSON.stringify(firstProgram))
    }

    if (!context.isAdmin && !selectedGlobalProgramId) {
      let firstProgram = context.userPrograms[0].id
      setSelectedGlobalProgramId(firstProgram)
      localStorage.setItem("selectedProgramId", JSON.stringify(firstProgram))
    }
  }

  if (auth.isLoading) {
    return (
      <CustomProgressDialog
        open={auth.isLoading}
        progress={null}
        title="Loading..."
      />
    )
  }

  if (!auth.isAuthenticated) {
    return <FailedLoginDialog />
  }

  const handleProgramSelect = (selection) => {
    setSelectedGlobalProgramId(selection)
  }

  const handlePendingChangesCount = (number, title) => {
    let isInventory = title.includes("Inventory")
    let isPC = title.includes("Pending")
    let newNum = isInventory ? pendingChangesCount + number : isPC && number === pendingChangesCount ? pendingChangesCount : number
    setPendingChangesCount(newNum)
  }

  const handlePendingChangesUpdate = () => {
    setPendingChangesUpdate(!pendingChangesUpdate)
  }

  const handleInventoryUpdate = () => {
    setInventoryUpdate(!inventoryUpdate)
  }
  const handleUsersUpdate = () => {
    setUsersUpdate(!usersUpdate)
  }

  const handleLeftMenuDrawer = () => {
    setLeftMenuDrawerOpen(!leftMenuDrawerOpen)
  }

  const handleAlertModal = () => {
    setIsAlertModalOpen(!isAlertModalOpen)
  }

  const handleUpdateUserPrograms = async (respStatus, form) => {
    if (respStatus === 201 || respStatus === 204 || respStatus === 200) {
      let refetchProg = await apiCalls.getRecords("ArmyPrograms")
      let formatted = await filterActiveData(refetchProg.data.value.sort((a, b) => a.name.localeCompare(b.name)))
      let updatedProgs = await finalizeUserPrograms(formatted)
      setUserProgramsList(updatedProgs)
    }
  }

  const handleUpdateIsInvLoading = (boolean, view) => {
    if (view.includes("inventory") || view.includes("COSIS")) {
      let newVal = boolean
      setIsInvLoading(newVal)
    }
  }

  const handleTransferItems = (itemsToBeHandled, mode) => {
    if (mode === "add") {
      let newArray = [...itemsToBeHandled, ...transferItems]
      setTransferItems(newArray)
      localStorage.setItem("transferItems", JSON.stringify(newArray))
    } else {
      const updatedTransferItems = transferItems.filter((item) => item.id !== itemsToBeHandled)
      setTransferItems(updatedTransferItems)
      localStorage.setItem("transferItems", JSON.stringify(updatedTransferItems))
    }
    notify("success", mode === "add" ? "Your item(s) have been staged for transfer." : "The item has been removed from the transfer stage.")
  }

  const handleTransferRequestSubmit = async (transferForm) => {
    const finalTransferItemIds = transferForm.transferItems.map((el) => el.inventoryRecordId)
    const transferItemsToDelete = new Set(finalTransferItemIds)
    const revisedTransferItemsData = transferItems.filter((el) => !transferItemsToDelete.has(el.id.toString()))

    // Get the program's ID in Keycloak; not to be confused with the ID in the database.
    const keycloakProgram = context.clientGroupData.filter((f) => f.name === transferForm.toProgramName)

    try {
      let response = await apiCalls.postRecord(transferForm, "TransferTransactions")
      setTransferItems(revisedTransferItemsData)
      localStorage.setItem("transferItems", JSON.stringify(revisedTransferItemsData))
      if (response.status === 201) {
        let fetchTransferTransactions = await apiCalls.getRecords("TransferTransactions")
        setAllTransferTransactionData(fetchTransferTransactions.data.value)
      }
      notify("success", "Your transfer request has been submitted.")
      setTimeout(() => {
        navigate(`/dashboard`)
      }, 3000)

      let body = {
        processType: "transfer",
        program: transferForm.toProgramName,
        programId: keycloakProgram[0].id,
        documentNumber: "",
        status: "PENDING",
        signature: "",
        containsAssetId: false,
      }

      await apiCalls.sendEmailNotifications(body)
    } catch (error) {
      console.error(error)
      notify("error", `There was a problem submitting the transfer request. Please try again.`)
    }
  }

  const handleTransferRequestComplete = async (transferForm, updateTransferForm) => {
    try {
      let updateTransfer = await apiCalls.putRecord(updateTransferForm, "TransferTransactions", transferForm.id)
      if (updateTransfer.status === 204) {
        let response = await apiCalls.deleteItemsByTransactionId(transferForm.id, "TransferTransactions", "TransferItems")
        if (response.status === 204) {
          notify("success", `Transfer request ${transferForm.documentNumber} has been completed.`)
          setTimeout(() => {
            navigate(`/dashboard`)
          }, 3000)

          // Get the program's ID in Keycloak; not to be confused with the ID in the database.
          const keycloakProgram = context.clientGroupData.filter((f) => f.name === transferForm.toProgramName)

          let body = {
            processType: "transfer",
            program: transferForm.toProgramName,
            programId: keycloakProgram[0].id,
            documentNumber: transferForm.documentNumber,
            status: "COMPLETE",
            signature: "",
            containsAssetId: false,
          }

          await apiCalls.sendEmailNotifications(body)
        }
      }
    } catch (error) {
      console.error(error)
      notify("error", `There was a problem completing the transfer request. Please try again.`)
    }
  }

  const handleAddItemsToCart = (itemsToBeAdded) => {
    let newArray = [...itemsToBeAdded, ...cartItems]
    setCartItems(newArray)
    localStorage.setItem("cartItems", JSON.stringify(newArray))
    notify("success", "Your items have been successfully added to your cart.")
  }

  const handleDeleteItemFromCart = (itemToDelete) => {
    const updatedCartItems = cartItems.filter((item) => item.id !== itemToDelete)
    setCartItems(updatedCartItems)
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems))
    notify("success", "Your item has been removed from your cart.")
  }

  const handleDeleteAllCartItems = (programId) => {
    const updatedCartItems = cartItems.filter((item) => item.managementRecordArmyProgramId !== programId)
    setCartItems(updatedCartItems)
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems))
    notify("success", "All items under this program have been removed from your cart.")
  }

  const handleSubmitForm = async (formattedFormObject, cartItemsToDisplay) => {
    const cartItemsIds = cartItemsToDisplay.map((el) => el.id)
    const cartItemsToDelete = new Set(cartItemsIds)
    const revisedCartItemsData = cartItems.filter((el) => !cartItemsToDelete.has(el.id.toString()))

    try {
      let response = await apiCalls.postRecord(formattedFormObject, "WarehouseRequestTransactions")
      setCartItems(revisedCartItemsData)
      localStorage.setItem("cartItems", JSON.stringify(revisedCartItemsData))
      if (response.status === 201) {
        let newFetch = await apiCalls.getRecords("WarehouseRequestTransactions")
        setAllWRTData(newFetch.data)
      }
      notify("success", "Your request has been submitted.")
      setTimeout(() => {
        navigate(`/dashboard`)
      }, 3000)

      // Get the program's ID in Keycloak; not to be confused with the ID in the database.
      const keycloakProgram = context.clientGroupData.filter((f) => f.name === formattedFormObject.programName)

      // Grab the property numbers of any records that have Asset Id values.
      let containsAssetId = cartItemsToDisplay.some((f) => f.assetIdentification !== null && f.assetIdentification !== "")
      let propertyNumbersWithAssetIds = []
      if (containsAssetId) {
        propertyNumbersWithAssetIds = cartItemsToDisplay
          .filter((f) => f.assetIdentification !== null && f.assetIdentification !== "")
          .map((g) => g.propertyNumber)
      }

      let body = {
        processType: "warehouse",
        program: formattedFormObject.programName,
        programId: keycloakProgram[0].id,
        documentNumber: response.data.documentNumber,
        status: "PENDING",
        signature: "",
        containsAssetId: containsAssetId, // NOTE: This property determines if an email should be sent to the Inventory Managers.
        propertyNumbers: propertyNumbersWithAssetIds, // NOTE: If containsAssetId is true, send all the records' property numbers that have an Asset Id value.
      }

      await apiCalls.sendEmailNotifications(body)
    } catch (error) {
      console.error(error)
      notify("error", `There was a problem submitting your request. Please try again.`)
    }
  }

  const handleSkipHazmat = async (id, signatureField, signatureValue) => {
    let toastMsgSuccess = signatureField === "hazmatSignature" ? "Hazmat Signature has been skipped." : null
    let toastMsgError =
      signatureField === "hazmatSignature"
        ? "There was a problem skipping the signature in this Request."
        : "There was a problem updating the Status of your Request."
    try {
      let patchResp = await apiCalls.patchRecord(id, "WarehouseRequestTransactions", signatureField, "replace", signatureValue)
      if (patchResp.status === 200) {
        let refetch = await apiCalls.getRecords("WarehouseRequestTransactions")
        setAllWRTData(refetch.data)
      }
      notify("success", `${toastMsgSuccess}`)
    } catch (error) {
      console.error(error)
      notify("error", `${toastMsgError} Please try again.`)
    }
  }

  const handleEditFormSubmit = async (id, requestForm, pathname) => {
    let isComplete = requestForm.status === "COMPLETE"
    let successMsg =
      pathname === "pending_request"
        ? "Your updates to the request have been submitted."
        : requestForm.nextSignature === "N/A"
        ? `Request ${requestForm.documentNumber} is COMPLETE.`
        : "Signature has been applied."

    try {
      if (isComplete) setLoaded(false)
      let response = await apiCalls.putRecord(requestForm, "WarehouseRequestTransactions", id)

      if (requestForm.status === "COMPLETE" && response.status === 204) {
        await apiCalls.deleteItemsByTransactionId(id, "WarehouseRequestTransactions", "RequestItems")

        // Get the program's ID in Keycloak; not to be confused with the ID in the database.
        const keycloakProgram = context.clientGroupData.filter((f) => f.name === requestForm.programName)

        let body = {
          processType: "warehouse",
          program: requestForm.programName,
          programId: keycloakProgram[0].id,
          documentNumber: requestForm.documentNumber,
          status: "COMPLETE",
          signature: "",
          containsAssetId: false,
        }

        await apiCalls.sendEmailNotifications(body)
      }
      if (response.status === 204) {
        let refetch = await apiCalls.getRecords("WarehouseRequestTransactions")
        let updatedWRTs = refetch.data
        setAllWRTData(updatedWRTs)
      }
      if (isComplete) setLoaded(true)
      notify("success", `${successMsg}`)
      if (pathname === "pending_request") {
        setTimeout(() => {
          navigate(`/dashboard`)
        }, 3000)
      }
    } catch (error) {
      console.error(error)
      if (isComplete) setLoaded(true)
      notify("error", `There was a problem updating this request. Please try again.`)
    }
  }

  const handleReopenOrCancelWRT = async (id, entity, patchChangesObj, action) => {
    let isReopen = action === "reopen"
    let successMsg = action === "reopen" ? `Request ${patchChangesObj.documentNumber} has been reopened.` : `Request has been cancelled.`
    try {
      let multiPatchResp = await apiCalls.patchRecordMultiple(id, entity, isReopen ? patchChangesObj.reopenWRTpatchChanges : patchChangesObj)
      if (multiPatchResp.status === 200) {
        if (!isReopen) await apiCalls.deleteItemsByTransactionId(id, "WarehouseRequestTransactions", "RequestItems")
        let refetchData = await apiCalls.getRecords("WarehouseRequestTransactions")
        setAllWRTData(refetchData.data)
      }
      notify("success", successMsg)
    } catch (error) {
      console.error(error)
      notify("error", `There was a problem ${isReopen ? "reopening" : "cancelling"} the request.`)
    }
  }

  const handleDeleteItemEditForm = async (id) => {
    try {
      let deleteResp = await apiCalls.deleteRecord(id, "WarehouseRequestItems")
      return deleteResp
    } catch (error) {
      console.error(error)
    }
  }

  // NOTE: Commenting out for now to try background tasks.
  //** WEBSOCKET CONNECTION FUNCTIONS **//
  // const startNewConnection = async (connectionId) => {
  //   try {
  //     console.log("Route:", process.env.REACT_APP_SIGNAL_R_HUB_URL)
  //     // Initiate a connection.
  //     const connection = new HubConnectionBuilder()
  //                   .withUrl(`${process.env.REACT_APP_SIGNAL_R_HUB_URL}`)
  //                   .configureLogging(LogLevel.Information)
  //                   .build()

  //     // Set up handlers.
  //     connection.on("StartConnection", (message) => {
  //       console.log("Message:", message)
  //     })

  //     connection.on("PingServerWithMessage", (data) => {
  //       console.log("Message:", data)
  //       console.log("Total Row Count:", data.value.totalRowCount)
  //       console.log("Current rows:", data.value.currentRowCount)
  //       console.log("Percentage completed:", (data.value.currentRowCount / data.value.totalRowCount) * 100)
  //       setProgress((data.value.currentRowCount / data.value.totalRowCount) * 100)
  //       setWebSocketMessages((oldArray) => [...oldArray, data.value.response])
  //       setIsConnectionAlive(true)
  //     })

  //     await connection.start()
  //     connectionId = connectionId.toString()
  //     await connection.invoke("StartConnection", {connectionId})

  //     setWebSocketConnection(connection)
  //   } catch(error) {
  //     console.log("Error:", error)
  //   }
  // }

  // const closeConnection = async (connectionToClose) => {
  //   if (connectionToClose) {
  //     console.log("Closing connection...")
  //     setWebSocketMessages([""])
  //     setIsConnectionAlive(false)
  //     await connectionToClose.stop()
  //   }
  // }
  if (!loaded) {
    return (
      <CustomProgressDialog
        open={!loaded}
        progress={null}
        title={"Loading..."}
      />
    )
  } else {
    return (
      <AppContext.Provider value={context}>
        <ThemeProvider theme={appTheme}>
          <AppRoutes
            handleUpdateUserPrograms={handleUpdateUserPrograms}
            handleUpdateIsInvLoading={handleUpdateIsInvLoading}
            isInvLoading={isInvLoading}
            userProgramsList={userProgramsList}
            handleDeleteAllCartItems={handleDeleteAllCartItems}
            handleSelect={handleProgramSelect}
            selectedGlobalProgramId={selectedGlobalProgramId}
            handleAddItemsToCart={handleAddItemsToCart}
            handleDeleteItemFromCart={handleDeleteItemFromCart}
            cartItems={cartItems}
            handleSubmitForm={handleSubmitForm}
            handleEditFormSubmit={handleEditFormSubmit}
            allWRTData={allWRTData}
            pendingChangesCount={pendingChangesCount}
            handlePendingChangesCount={handlePendingChangesCount}
            pendingChangesUpdate={pendingChangesUpdate}
            handlePendingChangesUpdate={handlePendingChangesUpdate}
            inventoryUpdate={inventoryUpdate}
            handleInventoryUpdate={handleInventoryUpdate}
            usersUpdate={usersUpdate}
            handleUsersUpdate={handleUsersUpdate}
            handleSkipHazmat={handleSkipHazmat}
            handleDeleteItemEditForm={handleDeleteItemEditForm}
            handleReopenOrCancelWRT={handleReopenOrCancelWRT}
            leftMenuDrawerOpen={leftMenuDrawerOpen}
            handleLeftMenuDrawer={handleLeftMenuDrawer}
            transferItems={transferItems}
            handleTransferItems={handleTransferItems}
            handleTransferRequestSubmit={handleTransferRequestSubmit}
            handleTransferRequestComplete={handleTransferRequestComplete}
            isAlertModalOpen={isAlertModalOpen}
            handleAlertModal={handleAlertModal}
          />
        </ThemeProvider>
      </AppContext.Provider>
    )
  }
}
export default App
