import { useState } from "react"

// ** MUI
import { Box, Button, Divider, Grid, IconButton, Modal } from "@mui/material"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import CloseIcon from '@mui/icons-material/Close'

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { style } from "../utils"

export const DeletePendingChangeModal = ({ shouldOpen, handleConfirmDelete, isPendingGroup, reference }) => {
  const [open, setOpen] = useState(shouldOpen)
  const isPendingGroupMessage = "This is a pending group change, meaning there are multiple records that have the same program, property, previous and new values. Removing "
    + "this group change will also remove all the associated pending records. Are you sure you'd like to delete this pending group change?"

  const showDeletePendingChangeText = () => {
    return (
      <>
        <p>Are you sure you want to delete this pending change?: </p>
        <p><b>Previous Value:</b> <span className="highlight-blue">{!reference.previousValue ? "[Empty]" : reference.previousValue}</span></p> 
        <p><ArrowDownwardIcon/></p>
        <p><b>New Value:</b> <span className="highlight-blue">{!reference.newValue ? "[Empty]" : reference.newValue}</span></p>
      </>
    )
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* CLOSE ICON */}
        <Grid
          container
          spacing={2}
        >
          <IconButton 
            onClick={() => handleConfirmDelete(false)}
          >
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid
          container
          spacing={2}
        >
          {/* TOP DIVIDER */}
          <Grid
            item
            xs={12}
            sx={{ paddingTop: "15px !important" }}
          >
            <Divider className="divider-title">
              Confirm Delete
            </Divider>
          </Grid>

          {/* TEXT */}
          <Grid
            item
            xs={12}
            sx={{ textAlign: isPendingGroup ? "left" : "center"}}
          >  
            { isPendingGroup ? isPendingGroupMessage : showDeletePendingChangeText() }
          </Grid>

          {/* BUTTON */}
          <Grid 
            item 
            xs={8.5} 
          />
          <Grid
            item
            xs={3.5}
          >
            <Button
              variant="contained"
              color="error"
              onClick={() => handleConfirmDelete(true)}
            >
              Delete
            </Button>
          </Grid>

          {/* BOTTOM DIVIDER */}
          <Grid 
            item 
            xs={12}
          >
            <Divider />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
