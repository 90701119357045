import { useContext, useEffect, useState } from "react"

// ** MUI
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"

// ** Styles
import "./styles/ModalStyles.css"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Context
import AppContext from "../AppContext"

// ** Custom
import { style, getWarehouseBuildingNumber } from "../utils"

export const WarehouseAssignmentModal = ({ shouldOpen, selectedProgram, handleConfirmAction }) => {
  let context = useContext(AppContext)
  const [open, setOpen] = useState(shouldOpen)
  const [radioValue, setRadioValue] = useState("")
  const [currentWarehouseList, setCurrentWarehouseList] = useState()
  const [warehouseToUnassign, setWarehouseToUnassign] = useState("")
  const [inventoryExistsMessage, setInventoryExistsMessage] = useState("")
  const [availableWarehouses, setAvailableWarehouses] = useState([])
  const [assignWarehouses, setAssignWarehouses] = useState([])
  const [isDisabled, setIsDisabled] = useState(true)

  // For multiple selection drop-down.
  const ITEM_HEIGHT = 30
  const ITEM_PADDING_TOP = 5
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  const handleRadioChange = (event) => {
    setRadioValue(event.target.value)

    setAssignWarehouses([])
    setWarehouseToUnassign("")
    setInventoryExistsMessage("")
  }

  const handleAssignChange = (event) => {
    const {
      target: { value },
    } = event

    setAssignWarehouses(value)
    setIsDisabled(value.length !== 0 ? false : true)
  }

  const handleUnassignChange = async (event) => {
    setInventoryExistsMessage("")
    setWarehouseToUnassign(event.target.value)

    // Check if inventory related to the selected program is still in the selected warehouse to remove.
    let inventory = await apiCalls.filterInventoryRecordsByAPandWhIds(selectedProgram.id, event.target.value)
    if (inventory.data.value.length > 0)
      setInventoryExistsMessage(
        `Inventory for ${selectedProgram.name} exists in ${getWarehouseBuildingNumber(
          event.target.value,
          context.warehouseData
        )}. You must transfer all the inventory to another warehouse before unassigning the selected warehouse.`
      )
    else setIsDisabled(false)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setIsDisabled(true)
  }, [radioValue])

  const fetchData = async () => {
    // For unassigning a warehouse:
    let programsWarehouseList = await apiCalls.getWarehousesListByArmyProgramId(selectedProgram.id)
    let sortedWarehouseList = programsWarehouseList.data.value[0].warehouseList.sort((a, b) =>
      a.warehouse.buildingNumber.localeCompare(b.warehouse.buildingNumber)
    )
    setCurrentWarehouseList(sortedWarehouseList)

    // For assigning new warehouses:
    let availableWarehousesList = context.warehouseData.filter((f) =>
      programsWarehouseList.data.value[0].warehouseList.every((e) => e.warehouseId != f.id)
    )
    setAvailableWarehouses(availableWarehousesList)
  }

  const assignDropDown = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="multiple-checkbox-label">Warehouses</InputLabel>
        <Select
          labelId="multiple-checkbox-label"
          id="multiple-checkbox"
          multiple
          value={assignWarehouses}
          onChange={handleAssignChange}
          input={<OutlinedInput label="Warehouses" />}
          renderValue={(selected) => selected.map((f) => f.buildingNumber).join(", ")}
          MenuProps={MenuProps}
        >
          {availableWarehouses.map((f) => (
            <MenuItem
              key={f.id}
              value={f}
            >
              <Checkbox checked={assignWarehouses.findIndex((item) => item.id === f.id) >= 0} />
              {f.buildingNumber}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }

  const unassignDropDown = () => {
    return (
      <FormControl fullWidth>
        <InputLabel id="unassign-warehouse-select-label">Warehouse</InputLabel>
        <Select
          labelId="unassign-warehouse-select-label"
          id="unassign-warehouse-select"
          value={warehouseToUnassign}
          label="Warehouse"
          onChange={(e) => {
            setIsDisabled(true)
            handleUnassignChange(e)
          }}
          MenuProps={MenuProps}
        >
          {currentWarehouseList.map((f) => {
            return <MenuItem value={f.warehouseId}>{f.warehouse.buildingNumber}</MenuItem>
          })}
        </Select>
      </FormControl>
    )
  }

  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        {/* CLOSE ICON */}
        <Grid
          container
          spacing={2}
        >
          <IconButton onClick={() => handleConfirmAction(false)}>
            <CloseIcon />
          </IconButton>
        </Grid>

        <Grid
          container
          spacing={2}
        >
          {/* TOP DIVIDER */}
          <Grid
            item
            xs={12}
            sx={{ paddingTop: "15px !important" }}
          >
            <Divider className="divider-title">Warehouse Assignment</Divider>
          </Grid>

          {/* TEXT / RADIO SELECTION */}
          <Grid
            item
            xs={12}
            sx={{ textAlign: "center" }}
          >
            {`Select whether to assign a warehouse(s) or unassign from ${selectedProgram.name}:`}
          </Grid>
          <Grid
            item
            xs={12}
          >
            <FormControl>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                sx={{ mb: 2 }}
                value={radioValue}
                onChange={handleRadioChange}
              >
                <FormControlLabel
                  value="assign"
                  control={<Radio />}
                  label="Assign"
                />
                <FormControlLabel
                  value="unassign"
                  control={<Radio />}
                  label="Unassign"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {/* DROP-DOWNS */}
          <Grid
            item
            xs={12}
          >
            {radioValue === "assign" ? assignDropDown() : radioValue === "unassign" ? unassignDropDown() : null}
          </Grid>

          {/* ERROR MESSAGE */}
          <Grid
            item
            xs={12}
          >
            <span className="highlight-red">
              <i>{!inventoryExistsMessage ? "" : inventoryExistsMessage}</i>
            </span>
          </Grid>

          {/* BUTTON */}
          <Grid
            item
            xs={8.5}
          />
          <Grid
            item
            xs={3.5}
          >
            <Button
              variant="contained"
              color={"primary"}
              onClick={() => handleConfirmAction(true, radioValue, radioValue === "unassign" ? warehouseToUnassign : assignWarehouses)}
              disabled={isDisabled}
            >
              Submit
            </Button>
          </Grid>

          {/* BOTTOM DIVIDER */}
          <Grid
            item
            xs={12}
          >
            <Divider />
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
