// Gets the application configuration
const _getAppConfig = async () => {
  window.BrowserPrint.getApplicationConfiguration(
    (success) => {
      console.log("app config success", success)
    },
    (err) => {
      console.log("app config error", err)
    }
  )
}

// Discover all of the devices that are available to the host system
const _getDeviceList = async () => {
  window.BrowserPrint.getLocalDevices(
    function (deviceList) {
      console.log(
        "Devices present in your network are: ",
        deviceList.printer,
        "typeof",
        typeof deviceList,
        "typeofdevicelistprinter",
        typeof deviceList.printer
      )
      return deviceList
    },
    (err) => {
      console.log("error inside getDeviceList", err)
    }
  )
}

const _defaultDevices = async () => {
  window.BrowserPrint.getDefaultDevice(
    "printer",
    function (device) {
      console.log(
        "device from _defaultDevices",
        device,
        "typeof device",
        typeof device,
        "device.Device",
        device.Device,
        "typeof deviceDevice",
        typeof device.Device
      )
      return device
    },
    function (error) {
      console.log("error in defaultDevices", error)
    }
  )
}

const _getConfig = (zebraPrinter) => {
  zebraPrinter.getConfiguration(
    function (response) {
      console.log("Configs are: ", response)
    },
    function (error) {
      console.error("***Error**** ", error)
    }
  )
}

const _getStatus = (zebraPrinter) => {
  zebraPrinter.getConfiguration(
    function (status) {
      console.log(status.getMessage())
    },
    function (error) {
      console.error("***Error**** ", error)
    }
  )
}

const _print = async (device, data = "~wc") => {
  console.log("selected device inside print", device, "data in print", data)
  device.send(
    data,
    function (success) {
      console.log("Sent to printer", success)
    },
    function (error) {
      console.error("Error print:" + error)
    }
  )
}

export const Print_Service = {
  getAppConfig: _getAppConfig,
  getDevices: _getDeviceList,
  defaultDevices: _defaultDevices,
  getConfig: _getConfig,
  getStatus: _getStatus,
  print: _print,
}
