import FilterArmyProgramsSelect from "./FilterProgramsSelect"
import FilterWarehousesSelect from "./FilterWarehousesSelect"
import "./styles/InventoryRecordsButtonStyles.css"
import FilterReportsSelect from "./FilterReportsSelect"

const InventoryRecordsButtonGroup = ({
  reportDefs,
  // armyData,
  // handleAPSelect,
  warehouseData,
  handleWarehouseSelect,
  selectedProgramId,
  handleFilterReportsSelect,
  selectedReport,
  isInvLoading,
  pathname,
}) => {
  return (
    <section className="filterSelects">
      <article className="select-wrapper">
        {/* <FilterArmyProgramsSelect
          armyData={armyData}
          handleAPSelect={handleAPSelect}
          selectedReport={selectedReport}
        /> */}
        {/* <FilterWarehousesSelect
          warehouseData={warehouseData}
          handleWarehouseSelect={handleWarehouseSelect}
        /> */}
      </article>
      <article className="select-wrapper">
        <FilterReportsSelect
          isInvLoading={isInvLoading}
          pathname={pathname}
          reportDefs={reportDefs}
          handleFilterReportsSelect={handleFilterReportsSelect}
          selectedProgramId={selectedProgramId}
        />
      </article>
    </section>
  )
}

export default InventoryRecordsButtonGroup
