import { useContext, useEffect, useState } from "react"

// ** MUI
import { Box, FormControl, InputLabel, ListItemText, MenuItem, OutlinedInput, Select } from "@mui/material"

// ** Context
import AppContext from "../AppContext"

export default function FilterProgramsSelect({ handleSelect, selectedGlobalProgramId, isInvLoading, selected }) {
  let context = useContext(AppContext)
  const [selectedProgramId, setSelectedProgramId] = useState(selectedGlobalProgramId)
  let isInvRec = selected === "inventory_records" || selected === "COSIS"
  let isMenuItemDisabled = isInvLoading
  let programListToUse = context.isAdmin ? "finalProgramsList" : "userPrograms"

  useEffect(() => {
    localStorage.setItem("selectedProgramId", JSON.stringify(selectedProgramId))
    setSelectedProgramId(selectedGlobalProgramId)
  }, [selectedProgramId])

  useEffect(() => {
    if (isInvRec && isInvLoading) {
      isMenuItemDisabled = true
    }
    if (isInvRec && !isInvLoading) {
      isMenuItemDisabled = false
    }
  }, [isInvLoading])

  const handleProgramChange = (event) => {
    setSelectedProgramId(event.target.value)
    handleSelect(event.target.value)
  }

  const usersPrograms = context[programListToUse]?.reduce((acc, program) => {
    let { isAdmin } = context
    if (!isAdmin || (isAdmin && !program.subPrograms && !program.parentProgramId)) {
      acc.push(
        <MenuItem
          disabled={isMenuItemDisabled}
          key={program.id}
          value={program.id}
        >
          {program.name}
        </MenuItem>
      )
    }
    if (isAdmin && program.subPrograms && !program.parentProgramId) {
      acc.push(
        <MenuItem
          disabled={isMenuItemDisabled}
          key={program.id}
          value={program.id}
        >
          {program.name}
        </MenuItem>
      )
      acc.push(
        ...program.subPrograms.map((subP) => (
          <MenuItem
            disabled={isMenuItemDisabled}
            key={subP.id}
            value={subP.id}
          >
            <ListItemText
              inset
              disableTypography
              sx={{ textAlign: "left" }}
            >
              {subP.name}
            </ListItemText>
          </MenuItem>
        ))
      )
    }
    return acc
  }, [])

  return (
    <Box sx={{ minWidth: 180, marginRight: "10px" }}>
      <FormControl
        required
        fullWidth
      >
        <InputLabel
          shrink
          id="program-select-label"
          sx={{ color: "black" }}
        >
          Program
        </InputLabel>
        <Select
          disabled={isMenuItemDisabled}
          sx={{ height: "50px" }}
          labelId="program-select-label"
          id="army-program-select"
          value={!selectedProgramId ? selectedGlobalProgramId : selectedProgramId}
          label="Program"
          onChange={(e) => handleProgramChange(e)}
          input={
            <OutlinedInput
              notched
              label="Program"
            />
          }
        >
          <MenuItem
            key="select-ap"
            disabled
            value=""
          >
            Select
          </MenuItem>
          {usersPrograms}
        </Select>
      </FormControl>
    </Box>
  )
}
