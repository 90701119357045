import { useContext, useEffect, useState } from "react"
import { Link, useLocation } from "react-router-dom"

// * MUI
import AddIcon from "@mui/icons-material/Add"
import ArchiveIcon from "@mui/icons-material/Archive"
import EditIcon from "@mui/icons-material/Edit"
import ListIcon from "@mui/icons-material/List"
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd"
import { Button, Stack } from "@mui/material"

// ** Toastify
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Custom
import AppContext from "../AppContext"
import { CustomStripedGrid } from "../components/CustomStripedGrid"
import { CustomModal } from "../components/CustomModal"
import Header from "../layout/Header"
import LoadingBackdrop, { getWarehouseBuildingNumber, notify } from "../utils"
import "../components/styles/AdditionalColumnStyles.css"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { WarehouseAssignmentModal } from "../components/WarehouseAssignmentModal"

const ArmyProgramsView = ({ leftMenuDrawerOpen, userProgramsList, handleUpdateUserPrograms }) => {
  const context = useContext(AppContext)
  const [loaded, setLoaded] = useState(false)
  const [data, setData] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isWarehouseAssignmentModalOpen, setIsWarehouseAssignmentModalOpen] = useState(false)
  const [selectedProgram, setSelectedProgram] = useState("")
  const [selectedDeleteId, setSelectedDeleteId] = useState("")
  const location = useLocation()
  const [pathname, setPathname] = useState("")
  const title = "Programs"

  useEffect(() => {
    fetchData()
  }, [userProgramsList])

  useEffect(() => {
    if (data) {
      setLoaded(true)
    }
  }, [data])

  const fetchData = async () => {
    if (userProgramsList) {
      setData(userProgramsList)
    }
    generateBlankArmyProgramsTemplate()
    setPathname(location.state?.path || window.location.pathname)
  }

  const generateBlankArmyProgramsTemplate = () => {
    const blankTemplate = context.army_programs.formFields.reduce((acc, currVal) => {
      if (!acc[currVal.field]) {
        acc[currVal.field] = ""
      }
      return acc
    }, {})
    context.blankArmyProgramsTemplate = blankTemplate
  }

  const handleApDelete = async (id, path) => {
    try {
      let deleteResp = await apiCalls.patchRecord(id, path, "ActiveState", "replace", false)
      await handleUpdateUserPrograms(deleteResp.status, id)
      setSelectedDeleteId("")
      notify("success", `${title} item archived successfully.`)
    } catch (error) {
      console.error("ERROR:", error)
      notify("error", "There was a problem archiving your item. Please try again.")
    }
  }

  const updateColumns = context.army_programs.columns.map((el) => {
    if (el.field === "actions") {
      el.getActions = (params) => {
        if (context.isAdmin) {
          return [
            <Stack showInMenu>
              <GridActionsCellItem
                icon={<EditIcon />}
                label="Edit"
                component={Link}
                to={`${pathname}/edit/${params.id}`}
                state={{ title: title }}
                showInMenu
              />
              <GridActionsCellItem
                icon={<ArchiveIcon />}
                label="Archive"
                onClick={() => {
                  toggleModal()
                  setSelectedDeleteId(params.row)
                }}
                showInMenu
              />
              <GridActionsCellItem
                icon={<PlaylistAddIcon />}
                label="WH Assignment"
                onClick={() => {
                  setSelectedProgram(params.row)
                  toggleWarehouseAssignmentModal()
                }}
                showInMenu
              />
              <GridActionsCellItem
                icon={<ListIcon />}
                label="Warehouses"
                component={Link}
                to={`/list_view/${params.id}`}
                state={{ isFromAP: true }}
                showInMenu
              />
            </Stack>,
          ]
        } else {
          return [
            <Stack showInMenu={true}>
              <GridActionsCellItem
                icon={<ListIcon />}
                label="Warehouses"
                component={Link}
                to={`/list_view/${params.id}`}
                state={{ isFromAP: true, pathname: pathname }}
                showInMenu={true}
              />
            </Stack>,
          ]
        }
      }
    }
    return el
  })

  const toggleModal = () => {
    setIsModalOpen(true)
  }

  const toggleWarehouseAssignmentModal = () => {
    setIsWarehouseAssignmentModalOpen(true)
  }

  const handleConfirmDelete = (response) => {
    if (response) {
      handleApDelete(selectedDeleteId.id, "ArmyPrograms")
    }
    setIsModalOpen(false)
  }

  // Handles warehouse assignments for a program.
  const handleConfirmAction = async (response, action, value) => {
    if (response) {
      try {
        if (action === "unassign") {
          let deleteResponse = await apiCalls.deleteRecord(`${value}/${selectedProgram.id}`, "ManagementRecords")
          if (deleteResponse.status === 204)
            notify("success", `${getWarehouseBuildingNumber(value, context.warehouseData)} has been unassigned from ${selectedProgram.name}.`)
        } else {
          await Promise.all(
            value.map(async (f) => {
              let request = {
                armyProgramId: selectedProgram.id,
                warehouseId: f.id,
              }

              await apiCalls.postRecord(request, "ManagementRecords")
            })
          ).then(() => {
            let buildingNumbersList = value.map((f) => f.buildingNumber).join(", ")
            notify(
              "success",
              `The following warehouse(s) ${value.length > 1 ? "have" : "has"} been assigned to ${selectedProgram.name}: ${buildingNumbersList}.`
            )
          })
        }
      } catch (error) {
        console.log("ERROR:", error)
        notify("error", "A problem occurred with the warehouse assignment(s). Please try again.")
      }
    }

    setIsWarehouseAssignmentModalOpen(false)
    setSelectedProgram("")
  }

  if (loaded) {
    return (
      <>
        <Header title={title} />
        {isModalOpen && (
          <CustomModal
            shouldOpen={true}
            handleConfirmDelete={handleConfirmDelete}
            pathname={pathname}
            title="Confirm Program Archive"
            reference={selectedDeleteId.name}
          />
        )}
        {isWarehouseAssignmentModalOpen && (
          <WarehouseAssignmentModal
            shouldOpen={true}
            selectedProgram={selectedProgram}
            handleConfirmAction={handleConfirmAction}
          />
        )}
        {context.isAdmin && (
          <div className="add-new-btn-wrapper">
            <Link
              to={`${pathname}/new`}
              state={{ pathname: pathname, title: title }}
            >
              <Button
                sx={{ fontSize: ".87rem", color: "black", fontWeight: "400" }}
                variant="contained"
                startIcon={<AddIcon />}
              >
                Add Program
              </Button>
            </Link>
          </div>
        )}
        <div>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
        <CustomStripedGrid
          pathname={pathname}
          data={data}
          columns={updateColumns}
          title={title}
        />
      </>
    )
  } else {
    return <LoadingBackdrop leftMenuDrawerOpen={leftMenuDrawerOpen} />
  }
}

export default ArmyProgramsView
