import { useContext, useEffect, useState } from "react"

// ** Toastify
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

import Header from "../layout/Header"
// ** Custom
import { applyCaps } from "../utils"

// ** API Calls
import apiCalls from "../apiCalls"

// ** Custom
import AppContext from "../AppContext"
import { notify, getFormattedDate, filterActiveNotIssuedData } from "../utils"
import { CustomStripedGrid } from "../components/CustomStripedGrid"
import FilterReportsSelect from "../components/FilterReportsSelect"

const COSISView = ({ leftMenuDrawerOpen, selectedGlobalProgramId, userProgramsList, handleUpdateIsInvLoading }) => {
  let context = useContext(AppContext)
  const title = "COSIS"
  const [COSISReportSelected, setCOSISReportSelected] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [COSISReportData, setCOSISReportData] = useState([])

  useEffect(() => {
    if (selectedGlobalProgramId && COSISReportSelected && userProgramsList) {
      let foundProg = userProgramsList.find((prog) => prog.id === selectedGlobalProgramId)
      handleSelectCOSISReport(foundProg)
    }
  }, [selectedGlobalProgramId, COSISReportSelected, userProgramsList])

  const handleFilterCOSISReportsSelect = (selection) => {
    setCOSISReportSelected(selection)
  }

  const handleSelectCOSISReport = async (foundProgram) => {
    setIsLoading(true)
    handleUpdateIsInvLoading(true, "COSIS")
    let COSISReportField = COSISReportSelected === "COSIS_requirements_report" ? "CosisDueDate" : "CosisCompleteDate"
    let isCosisDue = COSISReportField === "CosisDueDate"
    let currentDate = new Date()
    let comparison = isCosisDue ? "lt" : "gt"
    let dateForFilter = isCosisDue
      ? new Date(new Date().setDate(currentDate.getDate() + 30)).toISOString()
      : new Date(new Date().setDate(currentDate.getDate() - 30)).toISOString()
    try {
      let response
      if (foundProgram.subPrograms) {
        let subs = foundProgram.subPrograms.map((sub) => sub.id)
        response = await apiCalls.filterRecordsByCosisConditions({
          cosisReport: COSISReportField,
          date: getFormattedDate(dateForFilter),
          comparison: comparison,
          subProgramIds: [`${foundProgram.id}`, ...subs],
        })
      } else {
        response = await apiCalls.getRecords(
          `InventoryRecords?%24filter=ManagementRecordArmyProgramId%20eq%20${selectedGlobalProgramId}%20and%20${COSISReportField}%20${comparison}%20${getFormattedDate(
            dateForFilter
          )}%20or%20ManagementRecordArmyProgramId%20eq%20${selectedGlobalProgramId}%20and%20${COSISReportField}%20eq%20${getFormattedDate(
            dateForFilter
          )}`
        )
      }
      let dataToDisplay = await filterActiveNotIssuedData(foundProgram.subPrograms ? response.data : response.data.value)
      setCOSISReportData(dataToDisplay)
      setIsLoading(false)
      handleUpdateIsInvLoading(false, "COSIS")
    } catch (error) {
      console.error(error)
      setIsLoading(false)
      handleUpdateIsInvLoading(false, "COSIS")
      notify("error", `There was a problem generating your ${applyCaps(COSISReportSelected)}. Please try again.`)
    }
  }

  const { reportDefs } = context.inventory_records
  const reportColumnsToShow = context.inventory_records.reportDefs[COSISReportSelected]
  let columnsCOSIS = COSISReportSelected ? reportColumnsToShow : context.inventory_records.reportDefs["COSIS_completion_report"]

  return (
    <>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
      <Header title={title} />
      <section className="issued-year-wrapper">
        <FilterReportsSelect
          isInvLoading={isLoading}
          selectedArmyProgramId={selectedGlobalProgramId}
          reportDefs={reportDefs}
          handleFilterReportsSelect={handleFilterCOSISReportsSelect}
          pathname={title}
        />
      </section>
      {!COSISReportSelected && (
        <section className="issued-year-wrapper">Please select a COSIS report and program to generate the corresponding inventory.</section>
      )}
      <div style={{ height: "65vh", width: "100%" }}>
        <CustomStripedGrid
          data={COSISReportData}
          loading={isLoading}
          columns={columnsCOSIS}
          checkboxSelection={true}
        />
      </div>
    </>
  )
}

export default COSISView
