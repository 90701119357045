import * as React from "react"
import { useState } from "react"

// ** MUI
import { 
    Modal,
    Grid, 
    Box, 
    Button,
    Divider,
    IconButton
} from "@mui/material"
import CloseIcon from '@mui/icons-material/Close'

// ** Styles
import "./styles/ModalStyles.css"

// ** Custom
import { style } from "../utils"

// NOTE: This component handles displaying a warning for duplicate serial numbers when adding or editing record(s).
export const DuplicatesFoundModal = ({ shouldOpen, handleConfirmClose, title, text }) => {
    const [open, setOpen] = useState(shouldOpen)

    const showModalText = () => {
        return (
            <>
                <p>The following serial number(s) already exist with the specified NSN 
                    <span className="highlight-blue"> ({text.nationalStockNumber}) </span>
                    and P/N 
                    <span className="highlight-blue"> ({text.partNumber})</span>: 
                </p>
                <p className="highlight-blue">{text.intersectingSerials.join(', ')}</p>
                <p>
                    <span>If you wish to continue adding the duplicate(s), click </span>
                    <span className="highlight-red">BYPASS</span>
                    <span>, otherwise, exit out of this warning to fix the duplicate serial number(s).</span>
                </p>
            </>
        )
    }

    return (
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                {/* CLOSE ICON */}
                <Grid
                    container
                    spacing={2}
                >
                    <IconButton 
                        onClick={() => handleConfirmClose()}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>

                <Grid
                    container
                    spacing={2}
                    columns={11}
                >
                    {/* TOP DIVIDER */}
                    <Grid
                        item
                        xs={11}
                        sx={{ paddingTop: "15px !important" }}
                    >
                        <Divider className={"divider-title"}>
                            {title}
                        </Divider>
                    </Grid>

                    {/* TEXT/INPUT */}
                    <Grid
                        item
                        xs={11}
                        sx={{ textAlign: "center" }}
                    >
                        {showModalText()}
                    </Grid>
                    
                    {/* BUTTONS */}
                    <Grid
                        item
                        xs={8}
                    >
                    </Grid>
                    <Grid
                        item
                        xs={2}
                        sx={{ paddingTop: "20px !important" }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleConfirmClose("bypass")}
                        >
                            Bypass
                        </Button>
                    </Grid>
                    
                    {/* BOTTOM DIVIDER */}
                    <Grid
                        item
                        xs={11}
                    >
                        <Divider />
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
}